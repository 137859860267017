import { init } from '@ingka-group-digital/episod-client-js';
import { country, language, PROD } from '../constants';
import {
  getContentSquareMatchingKey,
  getLoggedInUserData,
  getSessionID as getPageviewId,
} from '../helpers';
import {
  getCookieConsent,
  isCookieConsentCountry,
  getPostalCode,
  getStoreId,
} from './helpers';

export const initEpisod = async () => {
  const cookieDomain = PROD ? '.ikea.com' : '.ikeadt.com';
  const url = PROD
    ? 'https://web-api.ikea.com/episod/inhouseEvent'
    : 'https://web-api.cte.ikeadt.com/episod/inhouseEvent';
  const xClientId = PROD
    ? '1ea83eeb-f16f-43d0-a11d-2487f62fb456'
    : 'a0bb26de-64e2-4000-8b31-076cdb84c29d';

  const { party_uid, customer_type } = await getLoggedInUserData();
  const consent = isCookieConsentCountry(country) ? getCookieConsent : null;
  const batch = country === 'om';
  const commons = {
    pageview_id: getPageviewId,
    market_code: country,
    event_origin: 'web',
    event_details: {
      postal_code: getPostalCode,
      store_id: getStoreId,
      location_language: language,
    },
    client_details: {
      cs_key: getContentSquareMatchingKey(),
      consent,
      party_uid,
      customer_type,
    },
  };

  init({
    cookieDomain,
    url,
    xClientId,
    commons,
    batch,
  });
};
