export const allowedEvents = [
  { category: 'test', actions: [] },
  { category: 'downloads', actions: [] },
  { category: 'optimizely', actions: [] },
  {
    category: 'internal_promotion_clicks',
    actions: ['banner', 'link', 'carousel'],
  },
  {
    category: 'survey',
    actions: ['impressions', 'responses', 'response', 'offered', 'clicked'],
  },
  { category: 'emergency_message', actions: ['impression', 'close'] },
  { category: 'value_proposition_message', actions: ['close', 'click'] },
  {
    category: 'page_message',
    actions: ['view', 'click'],
  },
  {
    category: 'user_feedback',
    actions: ['pulse_cx'],
  },
  {
    category: 'receipt_conversion',
    actions: ['view', 'click'],
  },
  {
    category: 'feedback',
    actions: ['positive' , 'negative'],
  },
  {
    category: 'customer_web',
    actions: ['login_intent_started', 'show_nudge', 'dismiss_nudge', 'login_click', 'goto_click',
              'out_of_capacity',
              'nudge_not_picked',
              'nudge_blocked',],
  },
  //{ category: 'value_proposition_links', actions: ['click'] },
  { category: 'timing', actions: ['timing_complete'] },
  { category: 'show_more', actions: [] },
  { category: 'ab_test', actions: ['search', 'labba', 'recommendations',] },
  {
    category: 'site_navigation',
    actions: [
      'menu',
      'breadcrumb',
      'body',
      'footer',
      'search',
      'header',
      'button',
      'products_open',
      'rooms_open',
      'theme_dark',
      'theme_default',
      'switch_language',
      'hamburger_menu_interaction',
      'main_navigation_click',
      'tab_click',
      'tabs_up',
      'switch_to_theme_dark',
      'switch_to_theme_light',
      'click_ikea_logo',
      'click_profile',
      'click_shopping_list',
      'click_shopping_bag',
      'switch_site',
      'device_aspect_ratio',
    ],
  },
  {
    category: 'magic_window',
    actions: [
      'open',
      'close',
      'add_product',
      'remove_product',
      'move_product',
      'view',
      'load_complete',
      'graphics_check',
      'save',
      'get_additional_details',
      'add_to_bag',
      'add_favorite',
      'remove_favorite',
      'add_room_to_bag',
    ],
  },
  {
    category: 'lotta',
    actions: [
      'allow_personal_email_check',
      'allow_personal_email_display',
      'allow_personal_email_uncheck',
      'back_to_ikea_click',
      'contest_page_load',
      'login_click',
      'sign_up_click',
      'sign_up_success',
      'upgrade_account_click',
      'contest_page_load_user_not_authorized',
      'contest_page_load_ikea_family_authorized',
      'game_started',
      'game_rules_confirmed',
      'game_over',
      'game_completed',
      'game_answer_correct',
      'game_answer_wrong',
      'game_answer_timeout',
      'game_quit',
    ],
  },
  {
    category: 'isx_contact_chat',
    actions: [
      'chatbot_open',
      'reactive_open',
      'proactive_open',
      'chatbot_start',
      'reactive_start',
      'proactive_start',
      'chatbot_close',
      'reactive_close',
      'proactive_close',
      'chatbot_handover',
      'webform_open',
      'webform_close',
      'webform_submit',
      'chatbot_first_message',
      'reactive_first_message',
      'proactive_first_message',
      'chat_pulsecx_offered',
      'chat_pulsecx_open',
      'proactive_handover',
      'proactive_offered',

    ],
  },
  {
    category: 'dyh_sugarcube',
    actions: [
      'open',
      'close',
      'add_product',
      'remove_product',
      'move_product',
      'view',
      'load_complete',
      'graphics_check',
      'save',
      'get_additional_details',
      'add_to_bag',
      'add_favorite',
      'remove_favorite',
      'add_room_to_bag',
      'learnmore_page_visit',
      'learnmore_watch_video_click',
      'learnmore_testimonial_click',
      'learnmore_try_showrooms_click',
      'learnmore_get_ikea_app_click',
      'learnmore_get_started_click',
      'choose_room_open_room',
      'choose_room_carousel_prev_click',
      'choose_room_carousel_next_click',
      'choose_room_get_ikea_app_click',
      'ikea_app_banner_click',
      'chooseRoom_page_visit',
      'dashboard_page_visit',
      'share_page_visit',
      'decorate_page_visit',
      'kioskLanding_page_visit',
      'error_page_visit',
      'hero_banner_click',
      'showroom_banner_click',
      'ownroom_banner_click',
      'open_room',
      'wordpress_page_visit',
      'searchBrowse_page_visit',
      'search_browse_get_ikea_app_click',
      'search_browse_showroom_click',
      'search_browse_open_room',
      'wordpress_button_click',
      'dashboard_use_case_click',
      'dashboard_hero_click',
      'dashboard_learn_more_click',
      'dashboard_get_ikea_app_click',
      'dashboard_blog_click',
      'dashboard_use_case_slider_handle',
    ],
  },
  {
    category: 'market_language_picker',
    actions: ['close', 'yes', 'no', 'yes_remember', 'no_remember'],
  },
  {
    category: 'csr_csp_searchresults',
    actions: ['search_action', 'click_action', 'scroll_action', 'visible_action','error_action','visit_action',],
  },
  {
    category: 'csr_csp_start_page',
    actions: ['visible_action', 'focus_action', 'click_action',],
  },
  {
    category: 'geo_popup',
    actions: ['close', 'yes', 'no', 'yes_remember', 'no_remember'],
  },
  { category: 'video', actions: ['start', '25', '50', '75', '100', 'stop'] },
  { category: 'audio', actions: ['start', '25', '50', '75', '100'] },
  { category: 'contact', actions: ['phone', 'email', 'chat'] },
  { category: 'remote_selling', actions: ['click', 'display'] },
  { category: 'prepaid_payment', actions: ['survey_submit'] },
  { category: 'top_menu_navigation', actions: [] },
  { category: 'outbound_link', actions: [] },
  {
    category: 'error',
    actions: [
      'view_item',
      'order_history_cancel',
      'order_assembly_service',
      'select_content',
      'returns',
      'cancellations',
      'reschedule',
      'order_rescheduled',
      'add_to_cart',
      'add_to_wishlist',
      'order_history',
      'purchase_history',
      'checkout',
      'api_error',
      'payment_error',
      'subscribe_stock_notification',
      'business_form',
      'cart',
      'family_form',
      'regular_form',
      'error',
      'js_error',
      'delete_profile',
      'edit_profile',
      'logins_regular_profile',
      'logout_regular_profile',
      'password_reset',
      'signup_begun_regular_profile',
      'signup_completed_regular_profile',
      'add_card',
      'remove_card',
    ],
  },
  {
    category: 'search',
    actions: [
      'autocomplete',
      'autocorrect',
      'zero_search_results',
      'product_suggest',
      'category_suggest',
      'content_suggest',
      'keyword_suggest',
      'did_you_mean',
      'popular_searches',
      'historical_searches',
      'product_recommendations',
      'hard_search',
      'related_searches',
      'cancel_autocorrect',
      'planner_suggest',
      'refined_searches',
      'visual_searches',
      'store_suggest',
      'category_popular',
      'product_recent',
      'search_type',
      'hard_search_autocorrect',
    ],
  },
  {
    category: 'search_box',
    actions: [
      'autocomplete',
      'autocorrect',
      'zero_search_results',
      'product_suggest',
      'category_suggest',
      'content_suggest',
      'keyword_suggest',
      'did_you_mean',
      'popular_searches',
      'historical_searches',
      'product_recommendations',
      'hard_search',
      'related_searches',
      'cancel_autocorrect',
      'focus',
      'planner_suggest',
      'visual_searches',
      'category_popular',
      'product_recent',
    ],
  },
  {
    category: 'search_survey',
    actions: ['rating', 'comment'],
  },
  {
    category: 'runnen_calculator',
    actions: ['select_dimension', 'calculate', 'confirm', 'recalculate',],
  },
  {
    category: 'box_navigation',
    actions: ['click',
              'view',
              'banner_interaction',
              'assistant_navigation',
              'exit',
              'enter_assistant',
              'assistant_submit',
              'buying_options_interest',
              'quantity_change',
              'experimentation',
              'filtering',
              'login',
              'logout',
              'menu_interaction',
              'onboarding',
              'product_detail',
              'image_preview',
              'contact_redirection',
              'search',
              'similar_product_request',
              'change_store',
              'change_postal_code',
              'experience_satisfaction',
              'see_variant',
              'load_more',
              'see_more_variants',],
  },
  {
    category: 'pip_media',
    actions: ['item_media_on_view', 'item_3d_media_on_view'],
  },
  {
    category: 'box_cart',
    actions: ['click',
              'view',
              'quantity_change',
              'select_delivery_method',
              'empty_cart',
              'taxes',
              'discount',
              'go_to_checkout',
              'add_to_cart',
              'add_project_to_cart',
              'remove_from_cart',
              'quantity_change',
              'assembly_selection',],
  },
  {
    category: 'box_checkout',
    actions: ['click',
              'view',
              'step_confirmation',
              'assistant_satisfaction',
              'delivery_date_interaction',
              'select_delivery_method',
              'edit_step',
              'error',
              'delivery_date_changed',
              'select_payment',
              'error_redirection',
              'contact_request',
              'retry_payment',
              'truck_delivery',
              'product_modal',
              'see_addresses',
              'create_address',
              'edit_address',
              'save_default_address',
              'paypal_info',
              'expand_product_list',
              'edit_product_list',
              'change_slot',
              'select_delivery_method',
              'change_pickups_view',
              'step_assistance',],
  },
  {
    category: 'box_confirmation',
    actions: ['click',
              'view',
              'survey_modal_action',
              'continue_shopping',
              'box_confirmation',],
  },
   {
    category: 'hri_offers',
    actions: ['hri-fragments-offerscategories-in-viewport',
              'hri-fragments-offerscategories-click',
              'hri-fragments-offers-in-viewport',
              'hri-fragments-offers-filter-click',
              'hri-fragments-offers-card-click',
              'hri-fragments-offers-product-click',
              'hri-fragments-offers-carousel-right',
              'hri-fragments-offers-carousel-left',
              'hri-fragments-offers-terms-click',
              'hri-fragments-curatedgallery-in-viewport',
              'hri-fragments-curatedgallery-card-click',
              'hri-fragments-lastchanceshelf-in-viewport',
              'hri-fragments-lastchanceshelf-product-click',
              'hri-fragments-lastchanceshelf-carousel-right',
              'hri-fragments-lastchanceshelf-carousel-left',
              'hri-fragments-lastchanceshelf-link-click',
              'hri-fragments-ikeafamilyshelf-in-viewport',
              'hri-fragments-ikeafamilyshelf-product-click',
              'hri-fragments-ikeafamilyshelf-carousel-right',
              'hri-fragments-ikeafamilyshelf-carousel-left',
              'hri-fragments-ikeafamilyshelf-link-click',],
  },
  {
    category: 'box_projects',
    actions: ['add_products',
              'go_back',
              'go_to_checkout',
              'new_empty_project',
              'exit',
              'submit_answer',
              'add_to_project',
              'create_new_project',
              'save_project',
              'share_project',
              'quantity_change',
              'go_to_projects',
              'image_preview',
              'delete_project',
              'restart_flow',
              'select_alternative',
              'access_project',
              'add_product_to_project',
              'remove_from_project',
              'change_tab'],
  },

  {
    category: 'ta_banner_actions',
    actions: [
      'sign_in_click',
      'sign_up_click',
      'upgrade_account_click',
      'learn_more_click',
      'banner_click',
      'legal_disclaimer_click',
      'disclaimer_modal_open_click',
      'disclaimer_modal_close_click',
      'disclaimer_modal_acknowledgement_click',
    ],
  },
  {
    category: 'pip_planner_actions',
    actions: [
      'planner_card_click',
      'mini_planner_card_click',
      'planner_card_video_pause',
      'planner_card_button_click',
      'planner_card_video_play',
      'runnen_add_to_cart_click',
      'runnen_calculate_click',
      'planner_card_click',

    ],
  },
  {
    category: 'fabric_samples_online',
    actions: [
      'display_landing_page',
      'login',
      'display_product_families',
      'select_product_family',
      'display_product_family_fabrics',
      'add_fabric_to_cart',
      'checkout_started',
      'checkout_completed',

    ],
  },
  {
    category: 'pub_curated_gallery',
    actions: ['hover', 'click'],
  },
  {
    category: 'pub_banner',
    actions: ['hover', 'click'],
  },
   {
    category: 'panel_interactions',
    actions: ['recommendations'],
  },
  {
    category: 'pub_visual_callout',
    actions: ['hover', 'click'],
  },
  {
    category: 'pub_image_with_text_box',
    actions: ['hover', 'content_cta_click'],
  },
  {
    category: 'pub_image_with_caption',
    actions: ['hover', 'click'],
  },
  {
    category: 'store_handover',
    actions: [
      'scan_qr_code',
      'sms_link',
      'email_link',
      'handover_qr',
      'handover_sms',
      'handover_email',
      'interstitial_order_all_items_online',
      'interstitial_order_single_item_online',
      'kiosk_direct_handover_online_items_qr',
      'kiosk_direct_handover_online_items_sms',
      'kiosk_direct_handover_online_items_email',
      'kiosk_direct_open_web_pip_qr',
      'kiosk_direct_open_web_pip_sms',
      'kiosk_direct_open_web_pip_email',
      'kiosk_handover_to_interstitial_qr',
      'kiosk_handover_to_interstitial_sms',
      'kiosk_handover_to_interstitial_email',
    ],
  },
  //ca
  {
    category: 'wishlist_actions',
    actions: ['click_and_collect'],
  },
  {
    category: 'search_backend',
    actions: ['usergroup', 'result_counts'],
  },
  {
    category: 'buyback',
    actions: ['load_category', 'click', 'display'],
  },
  {
    category: 'clean_energy_services',
    actions: ['click', 'conversion_funnel', 'faq'],
  },
  {
    category: 'filter',
    actions: [
      'filter_by_color',
      'filter_by_price',
      'filter_by_category',
      'filter_by_area',
      'filter_by_buyable_online',
      'filter_by_news',
      'filter_by_offer',
      'filter_by_price_offer',
      'filter_by_new_lower_price',
      'filter_by_outgoing_products',
      'filter_by_activity',
      'filter_by_room',
      'filter_by_theme',
      'filter_by_content',
      'filter_by_shape',
      'filter_by_seats',
      'filter_by_rating_value_integer',
      'filter_by_reference_measurements',
      'filter_by_materials',
      'filter_by_mattress_material',
      'filter_by_firmness',
      'filter_by_sleeping_position',
      'filter_by_feature',
      'filter_by_number_of_drawers',
      'filter_by_form',
      'filter_by_toggles',
      'filter_by_energy_label',
      'filter_by_doors',
      'filter_by_measurement_bucket',
      'filter_by_filling_material',
      'filter_by_material_quality',
      'filter_by_pattern',
      'filter_by_temperature',
      'filter_by_theme_motif',
      'filter_by_system',
      'filter_by_storage_for',
      'filter_by_function',
      'filter_by_type',
      'filter_by_family',
      'filter_by_volume',
      'filter_by_energy_classification',
      'filter_by_number_of_cooking_zones',
      'filter_by_noise_level',
      'filter_by_capacity',
      'filter_by_online_sellable',
      'filter_by_new_product',
      'filter_by_special_price',
      'filter_by_home_smart',
      'filter_by_last_chance',
      'filter_by_available_in_store',
      'filter_by_light_filtration',
      'filter_by_base',
      'filter_by_more_sustainable_materials',
      'filter_by_brightness',
      'filter_by_appearance',
      'filter_by_light_color',
      'filter_by_suitable_for',
      'filter_by_series',
      'filter_by_fits_in',
      'filter_by_quality',
      'filter_by_suitable_for_business',
      'filter_by_plant_care',
      'filter_by_minimum_temperature',
      'filter_by_surface_density',
      'filter_by_top_seller',
      'filter_by_front',
      'filter_by_recent',
      'filter_by_visual_category',
    ],
  },
  {
    category: 'filter_deselect',
    actions: [],
  },
  {
    category: 'sorting',
    actions: [
      'sort_by_price_high',
      'sort_by_price_low',
      'sort_by_news',
      'sort_by_name',
      'sort_by_name_ascending',
      'sort_by_name_descending',
      'sort_by_popularity',
      'sort_by_relevance',
      'sort_by_ascending',
      'sort_by_descending',
      'sort_by_rating',
      'sort_by_width',
      'sort_by_height',
      'sort_by_depth',
      'sort_by_length',
      'sort_by_most_popular',
      'sort_by_newest',
      'sort_by_ikea_recommends',
      'sort_by_discount_percentage_high',
    ],
  },
  { category: 'ratings_reviews', actions: [] },
  {
    category: 'page_action',
    actions: ['modal', 'inside_close', 'outside_close', 'escape_close', 'continue_shopping_close',],
  },
  {
    category: 'navigation',
    actions: ['main_menu', 'category_menu', 'subcategory_menu'],
  },
  {
    category: 'stock_check',
    actions: [
      'high',
      'medium',
      'low',
      'none',
      'in_stock_high',
      'in_stock_medium',
      'in_stock_low',
      'available_by_order',
      'out_of_stock',
      'error',
    ],
  },
  {
    category: 'rotera',
    actions: [
      'rotera_open_modal',
      'rotera_close_modal',
      'rotera_interact_3d_object',
      'rotera_arbutton_activated',
      'rotera_3dmodel_exists',
      'rotera_behaviour_view_product_top',
      'rotera_behaviour_view_product_back',
      'rotera_behaviour_view_product_bottom',
      'rotera_behaviour_view_product_zoom',
      'rotera_expand_3d_model',
      'rotera_contract_3d_model',
      'rotera_cta_click_kreativ',
      'rotera_switch_tab_3d_model',
      'rotera_switch_tab_kreativ',
      'rotera_style_picker_thumbnail',
      'rotera_kreativ_video_pause',
      'rotera_kreativ_video_play',
      'rotera_kreativ_video_autoplay',
      'rotera_cross_integration',
      'rotera_ar_opened_through_qr',
      'rotera_switch_tab_general_planner',
      'rotera_cta_click_general_planner',
      'rotera_open_availability_prompt',
      'rotera_studio_activated',
      'rotera_studio_add_to_cart',
      'rotera_studio_add_to_wishlist',
      'rotera_studio_remove_from_wishlist',
      'rotera_show_dimensions',
      'rotera_hide_dimensions',
      'rotera_ar_menu_toggle',
      'rotera_general_planner_menu_toggle',
      'rotera_kreativ_planner_menu_toggle',
      'rotera_variations_menu_toggle',
      'rotera_open_ar_activated',
      'rotera_engagement_time_event',
      'rotera_feeling_lucky_menu_toggle',
      'rotera_modal_size',
    ],
  },
  {
    category: 'pip_page_actions',
    actions: [
      'variant_selection',
      'change_amount',
      'pip_menu_clicks',
      'product_click',
      'add_to_cart_popup_action',
      'display_recommendations',
      'image_navigation_click',
      'link_clicks',
      'expand_panel',
      'image_click',
      'image_swipe',
      'image_thumbnail_click',
      'image_navigation_click',
      'navigate_panel',
      'close',
      'open_delivery_summary',
      'open_stock_summary',
      'open_click_collect_summary',
      'subscribe_stock_notification',
      'reviews_click',
      'reviews_close',
      'reviews_next',
      'reviews_prev',
      'reviews_page',
      'hero_image_click',
      'media_grid_click',
      'stock_check_click',
      'stock_store_change_click',
      'stock_store_change_button_click',
      'delivery_availability',
      'scroll_into_view',
      'pip_details_clicks',
      'monthly_pay_detailed_view',
      'monthly_pay_apply',
      'monthly_pay_continue',
      'show_more_images_button_click',
      'stock_notify_me_click',
      'assembly_services_click',
      'energy_label_click',
      'thumbnail_variant_selector_click',
      'buy_module_upsell_tick',
      'buy_module_upsell_untick',
      'buy_module_upsell_close',
      'free_market_message_click',
      'sold_separately_disclaimer_click',
      'article_number_click',
      'sustainability_circles_click',
      'measurements_header_click',
      'long_text_small_header_click',
      'measurement_image_modal',
      'style_picker_selection',
      'select_options',
      'modal_close',
      'media_nav_right_click',
      'media_nav_left_click',
      'media_nav_right_swipe',
      'media_nav_left_swipe',
      'energy_information_sheet_click',
      'energy_product_fiche_click',
      'delivery_availability_message_click',
      'measurement_modal_size_selector_click',
      'buy_module_measurement_click',
      'availability_market_message_click',
      'upsell_plus_button',
      'upsell_dropdown',
      'mattress_icon_click',
      'collect_availability_click',
      'collect_store_change_click',
      'stock_check_back_button',
      'stock_check_modal_close',
      'stock_check_search_box',
      'stock_check_available_toggle',
      'stock_check_radio_button',
      'collect_check_back_button',
      'collect_check_modal_close',
      'collect_check_search_box',
      'collect_check_available_toggle',
      'collect_check_radio_button',
      'keyfact_click',
      'pip_measurements_clicks',
      'stock_check_info',
      'collect_availability_info',
      'custom_made_info',
      'media_grid_pause',
      'media_grid_play',
      'media_video_pause',
      'media_video_play',
      'media_video_mute',
      'media_video_unmute',
      'media_video_watch_25',
      'media_video_watch_50',
      'media_video_watch_75',
      'media_video_watch_100',
      'media_video_full_screen',
      'change_store_click',
      'store_check_search_box',
      'store_check_radio_button',
      'store_check_available_toggle',
      'energy_compliance_images_link_click',
      'ikea_family_modal_full_benefits_button_click',
      'ikea_family_modal_full_benefits_link_click',
      'ikea_family_modal_join_button_click',
      'ikea_family_learn_more_link_click',
      'ikea_family_modal_login_button_click',
      'ikea_family_modal_terms_and_conditions_link_click',
      'assembly_availability_opened',
      'assembly_availability_modal',
      'assembly_availability_closed',
      'media_image_zoom',
      'fs_pip_mp_click_openside',
      'fs_pip_sw_mp_click_openfp',
      'pip_quantity_picker_interaction',
      'open_store_modal',
      'store_modal_click',
      'pip_closest_stores',
      'pip_closest_stores_availability',
      'pip_closest_stores_modal',
      'ao_pip_mp_click_openmodal',
      'ao_pip_mp_click_closemodal',
      'ao_pip_sw_mp_click_openfp',
      'qa_click',
      'qa_pip_menu_clicks',
      'pip_back_of_to_hero',
      'pip_back_off_to_hero',
      'pip_upsell_exists',
      'floor_calculator',
      'pip_closest_stores_modal',
      'ao_pip_mp_click_openmodal',
      'ao_pip_mp_click_closemodal',
      'qa_click',
      'qa_pip_menu_clicks',
      'ao_pip_mp_click_openfp',
      'apply_pay_start',
      'ao_pip_mp_click_afterpay_signup',
      'ao_pip_mp_click_afterpay_infopage',
      'installation_type_disclaimer_click',
      'assembly_services_available',
      'locate_in_store',
      'media_modal_open',
      'media_modal_nav_down',
      'media_modal_nav_up',
      'media_modal_video_tab',
      'media_modal_measurements_tab',
      'media_modal_thumbnail',
      'media_modal_hover',
      'media_modal_video_play',
      'locate_in_store_link_click',
      'media_modal_thumbnails_up',
      'media_modal_thumbnails_down',
      'delivery_price_missing',
      'delivery_time_missing',
      'media_modal_video_query_parameter',
      'key_benefits_show_more_link_click',
      'pip_free_delivery_learn_more'
    ],
  },
  {
    category: 'cart_page_actions',
    actions: [
        'ao_cart_mp_click_openmodal',
        'ao_cart_mp_click_closemodal',
        'ao_cart_mp_click_openfp',
        'apply_pay_start',
        'ao_cart_mp_click_afterpay_signup',
        'ao_cart_mp_click_afterpay_infopage',
    ],
  },
  {
    category: 'circular_hub',
    actions: [],
  },
  {
    category: 'circular',
    actions: ['display','click','select','search','rating'],
  },
  //MX
  {
    category: 'ispc_generated',
    actions: ['sl_ispc_clicked'],
  },

  {
    category: 'start_page_actions',
    actions: [
      'variant_selection',
      'change_amount',
      'link_clicks',
      'expand_panel',
      'navigate_panel',
    ],
  },
  {
    category: 'pip_availability',
    actions: [
      'item_availability_on_store_selection',
      'item_availability_on_postcode_submit',
      'item_availability_on_view',

    ],
  },
  {
    category: 'plp_page_actions',
    actions: [
      'image_toggle',
      'variant_selection',
      'open_filter_btn',
      'enable_filter',
      'close_filter',
      'cancel_filter',
      'reset_filter',
      'change_amount',
      'link_clicks',
      'expand_panel',
      'navigate_panel',
      'load_more',
      'next',
      'previous',
      'color_dots_click',
      'thumbnail_click',
      'more_thumbnail_click',
      'breakout_click',
      'breakout_view',
      'content_click',
      'content_collapse',
      'content_view',
      'content_view_percent',
      'content_expand',
      'product_comparison_open',
      'product_comparison_close',
      'product_comparison_clear',
      'product_comparison_add_product',
      'product_comparison_remove_product',
      'product_comparison_navigate',
      'product_comparison_activate',
      'product_comparison_deactivate',
      'product_list_view',
      'result_toggle',
      'guide_in_view',
      'guide_started',
      'guide_question_2',
      'guide_question_3',
      'guide_completed',
    ],
  },
  {
    category: 'pub_page_actions',
    actions: [
      'cta_click',
      'gift_card_submit',
      'gift_card_number',
      'simulator_loan_amount',
      'simulator_loan_duration',
      'apply_pay_start',
      'apply_pay_end',
      'prepaid_digial_wallet_query',
      'prepaid_qr_access',
      'prepaid_ab_test_yes',
      'prepaid_ab_test_no',
      'simulation_loan_name',
      'fshi_start_new_project​',
      'fshi_continue_existing_project​',
      'fshi_add_items',
      'fshi_add_services​',
      'fshi_save_project',
      'fshi_login_to_save',
    ],
  },
  {
    category: 'claim_product_damaged',
    actions: [
      'product_select',
      'resolution',
      'delivery',
      'review',
      'confirmation',
      'no_service',
     ],
  },
  {
    category: 'product_comparison',
    actions: [
      'edit_selection',
      'read_more',
      'product_count',
      'product_ids',
      'product_area',
      'product_click',
      'add_to_cart_click',
      'add_to_wishlist_click',
      'add_to_comparison',
      'remove_from_comparison',
      'show_only_differences',
      'open_slide_show',
      'open_modal',
      'table_header_link'
    ],
  },
  {
    category: 'planners',
    actions: [
      'first_interaction_event',
      'open_planner',
      'planner_template',
      'planner_progress',
      'planner_modification',
      'planner_product_deduplication',
      'save_planner',
      'email_planner',
      'print_planner',
      'filter_planner',
      'reset',
      'change_size',
      'show_products',
      'show_dimensions',
      'configuration_start',
      'top_menu',
      'switch_configurator_element',
      'filter_planner',
      'product_detail_light',
      'add_to_configurator',
      'delete_from_configurator',
      'product_color_change',
    ],
  },
  {
    category: 'planner_page_actions',
    actions: [
      'cta_click',
    ],
  },
  {
    category: 'search_page_actions',
    actions: [],
  },
  {
    category: 'search_box_actions',
    actions: [],
  },
  {
    category: 'user_actions',
    actions: [
      'login',
      'password_reset',
      'logins_regular_profile',
      'signup_begun_regular_profile',
      'signup_completed_regular_profile',
      'logins_ikea_family',
      'signup_begun_ikea_family',
      'signup_complete_ikea_family',
      'other_forms',
      'logins_ikea_business',
      'signup_begun_ikea_business',
      'signup_complete_ikea_business',
      'edit_profile',
      'delete_profile',
      'rewards_see_Ikea_family',
      'rewards_earn_keys',
      'rewards_click',
      'more_rewards_and_keys',
      'rewards_unlock',
      'rewards_unlock_confirm',
      'rewards_get_started',
      'rewards_click_project',
      'rewards_list_of_links',
      'rewards_list_of_planners',
      'rewards_share_your_opinion',
      'business_profile_signup_begun',
      'business_profile_signup_completed',
      'login_loyalty_hub_id',
      'open_loyalty_hub',
      'customer_interact_section',
      'spaces_click_get_started',
      'spaces_click_project',
      'customer_save_preferred_store',
      'family_form',
      'regular_form',
      'customer_project',
      'login_form',
      'signup_form',
      'add_card',
      'remove_card',
    ],
  },
  {
    category: 'self_serve_actions',
    actions: [
      'catalogue',
      'order_history_cancel',
      'order_assembly_service',
      'order_rescheduled',
      'kci_signup',
      'returns',
      'cancellations',
      'order_catalogue',
      'catalogue_order_complete',
      'event_signup_begun',
      'event_signup_complete',
      'event_signup_cancelled',
      'event_reminder',
      'delivery_tracking_active',
      'delivery_tracking_previous',
      'appointment',
    ],
  },
  {
    category: 'order_history',
    actions: [],
  },
  {
    category: 'purchase_history',
    actions: [
      'lookup',
      'delivery',
      'manage_purchase',
      'receipt',
      'tsp_link',
      'cancellation',
      'reschedule',
      'assembly',
      'return',
      'rating_assembly',
      'rating_reschedule',
      'rating_cancellation',
      'rating_details',
      'rating_list',
      'parcel_lab',
      'delivery_status_modal',
      'delivery_address_modal',
      'delivery_products_modal',
      'rating_product',
      'cancellation-deviation',
      'reschedule-deviation',
      'contactCsc-deviation',
      'text_feedback',
      'returns',
      'claim',
      'noStockCheckout',
      'purchase_info_modal',
      'sign_in',
      'tsp_tracking_id',
      'rating_details_text_feedback',
      'rating_details_description',
      'rating_details_sub_description',
      'rating_details_sub_sub_description',
      'remove_item',
      'remove_delivery',
      'map',
      'global_loading_banner',
      'buy_back',
      'fe_appversion',
      'article_delivery',
      'article_order',
      'order_products_modal',
      'pagination',
      'spareparts',
      'reorder',
      'boka',
      'store_order',
      'show_more',
      'invoice',
      'history',
      'enable',
      'select_order',
      'applied_filters',
      'rating_reschedule_description',
      'rating_reschedule_sub_description',
      'rating_list_description',
      'rating_list_sub_description',
      'rating_list_text_feedback',
      'select_order',
    ],
  },
  {
    category: 'inspiration',
    actions: [
      'click',
      'image_click',
      'load_more',
      'feed_views',
      'dot_click',
      'hero_tag_click',
      'shop_all_click',
      'shop_all_hover',
      'feed_end',
      'open_modal',
      'view_results',
      'view_button_click',
      'reset_button_click',
      'close_modal_click',
      'escape_modal_click',
      'added_to_inspiration',
      'removed_from_inspiration',
      'add_to_favourites',
      'remove_from_favourites',
      'click_to_inspiration_list',
      'favourites_image_click',
      'favorited_images',
      'get_inspired_from_favourites_click',
      'hero_dot_click',
      'show_products_click',
      'compact_feed_views',
      'compact_feed_end',
      'compact_feed_loaded',
      'vugc_feed_views',
      'vugc_feed_end',
      'vugc_feed_loaded',
      'distance_from_top',
      'feed_loaded',
      'time_to_click',
      'dot_click_mobile',
      'dot_click_desktop',
      'dot_hover',
      'view_more',
      'open_modal_desktop',
      'open_modal_mobile',
      'feed_add_to_favourite',
      'feed_remove_from_favourite',
      'view_item_list',
      'dot_hover',
    ],
  },
  {
    category: 'gift_registry',
    actions: [
      'registry_add_item',
      'registry_edit_cover_image',
      'registry_edit_title',
      'registry_edit_description',
      'registry_edit_event_date',
      'registry_share',
      'registry_renew_link',
      'registry_stop_sharing',
      'registry_remove_item',
      'gift_bag_add_item',
      'gift_bag_remove_item',
      'gift_bag_add_message',
      'checkout_started',
      'checkout_canceled',
      'reservation_canceled',
      'reservation_refreshed',
      'purchased_offline',
      'gift_bag_change_item_quantity',
      'report_content',
      'registry_change_primary',
      'registry_go_to_primary',
      'registry_go_to_not_primary',
      'login_modal',
      'registry_change_item_quantity',
      'error',
      'registry_create',
      'registry_delete',
      'gift_bag_purchase_preference',
      'gift_bag_toggletip',
      'gift_bag_info',
      'registry_claim',
      'registry_settings',
      'registry_settings_tab',
      'registry_download_pdf',
      'filter_products',
      'inspiration_scrollto',
      'nextstep_login',
      'find_registry',
      'onboarding_skipdelivery',
      'onboarding_addaddress',
      'onboarding_addwish',
      'start_instore',
      'complete_instore',
      'cancel_instore',
      'checkout_leave',
      'gifts_notavailable',
      'delivery_notavailable',
      'delivery_addaddress',
      'coowner_generate',
      'giftgiver_account',
      'onboarding_login',
      'read_giftmessage',
      'registry_opentheatre',
      'registry_productlink',
      'giftgiver_faq',
      'registry_mostdesired',
      'go_to_survey',
      'pilotdisclaimer',
      'registry_referral',
      'sharedregistry_availability',
      'giftgiver_giftcard',
      'registry_bundle',
      'add_in_store',
      'feedback',
      'giver_filter',
      'receiver_filter',
      'audit_log_event'
    ],
  },
  {
    category: 'sustainability_guide',
    actions: [
      'view_entrance_page',
      'click_to_first_page',
      'arrow_back',
      'arrow_forward',
      'exit_guide',
      'progress_bar_click',
      'guide_view',
      'button_click',
      'view_end_page',
      'topic_click',
      'button_click',
      'share_guide',
      'guide_view_product',
      'click_to_guide_page',
      'click_to_products',
      'read_more',
    ],
  },
  {
    category: 'share_to_socialmedia',
    actions: [
      'outside',
      'close',
      'click_pinterest',
      'click_facebook',
      'click_twitter',
      'click_sinaweibo',
      'click_link',
    ],
  },
  {
    category: 'home_furnishing_guidance',
    actions: [
      'banner_in_viewport',
      'cta_click',
      'choose_furniture',
      'choose_solution',
      'choose_room_state',
      'choose_name_room',
      'save_guidance',
      'exit_quiz',
      'customize_wardrobe',
      'click_back',
      'create_guide',
      'exit_save',
      'pick_action',
      'view_items',
      'view_product',
      'view_planner',
      'view_kreativ',
      'view_content',
      'book_appointment',
      'view_items_modal',
      'view_product_modal',
      'exit_items_modal',
      'view_bundle',
      'view_bundle_product',
      'view_bundle_modal',
      'view_bundle_modal_product',
      'exit_bundle_modal' ,
    ],
  },
  {
    category: 'location_picker',
    actions: [
      'open',
      'close',
      'search_by_entry',
      'search_by_location',
      'pick_store',
      'select_different_store',
      'get_directions',
      'visit_store_page',
    ],
  },
  {
    category: 'postalcode_picker',
    actions: [
      'open_header',
      'outside',
      'close',
      'postal_code',
      'forget',
      'has_postal_code',
      'open_pubsub',
      'geolocation_html_ok',
      'geolocation_html_fail',
      'geolocation_server_ok',
      'geolocation_server_fail',
      'geolocation_geocoding_ok',
      'geolocation_geocoding_fail',
    ],
  },
  {
    category: 'checkout_actions',
    actions: [
      'increase_amount',
      'decrease_amount',
      'calculate_delivery',
      'taskrabbit_postal_code',
      'assembly_available',
      'assembly_added',
      'assembly_removed',
      'selected_date',
      'created_cart',
      'delivery_map',
      'cta_button_click',
      'cart_view',
      'open_apple_pay',
      'personal_coupon_applied',
      'customer_has_personal_coupons',
      'services_modal_open',
      'services_zipin',
      'services_zipin_automatic',
      'services_select_item',
      'services_change_date',
      'giftcard',
      'error',
      'remove_and_wishlist_unavailable_products',
      'remove_and_wishlist_unavailable_product',
      'remove_unavailable_products',
      'remove_unavailable_product',
      'address_review_reject',
      'zipcode_change',
      'back_to_cart',
      'zipcode_edit',
      'zipcode_change_serviceoffers',
      'address_verification_pop_up',
      'logged_in_address_prefill',
      'logged_in_address_non_prefill',
      'click_address_suggestion',
      'click_modify_address',
      'click_unable_find_address',
      'address_section_edit',
      'click_expanded_mini_bag_order_summary',
      'earliest_delivery_date_hd',
      'earliest_delivery_date_cc',
      'service_time_window_change',
      'verify_address_modal',
      'update_profile_address',
      'cancel_payment',
      'removal_services_modal_open',
      'removal_services_zipin',
      'services_modal_closed',
      'removal_services_modal_closed',
      'preview-design',
      'core_web_vital',
      'services_entry_point_more_info',
      'removal_services_entry_point_more_info',
      'services_modal_more_info',
      'removal_services_modal_more_info',
      'address_selection',
    ],
  },
  {
    category: 'favourites',
    actions: [
      'create_list',
      'add_to_cart',
      'view_received_list',
      'login',
      'favourites_landing_page_no_lists',
      'subscribe_stock_notification',
      'promote_family_click',
      'add_to_registry',
      'store_customer',
      'find_in_store',
      'list_view',
      'list_shared',
      'list_qr',
      'store_intent',
      'share_list'
    ],
  },
  {
    category: 'donation',
    actions: [
      'unchr_modal_opened',
      'unchr_modal_closed',
    ],
  },
  {
    category: 'checklist_actions',
    actions: [
      'item_checkbox_click',
      'sublist_link_click',
      'item_link_click',
      'ikea_family_sign_up_click',
      'pdf_download_click',
    ],
  },
  {
    category: 'returns',
    actions: [
      'return_request',
      'enter_purchase_id',
      'select_products',
      'select_reasons',
      'edit_contact_info',
      'summary_shown',
      'terms_action',
      'select_return_method',
      'select_refund_option',
      'select_return_label_delivery_method',
      'products_added_manually',
      'products_fetched',
      'barcode_scanned',
    ],
  },
  {
    category: 'local_tracking_checkout_actions',
    actions: ['save_cart_click', 'availability_zipcode_check'],
  },
  //SE
  {
    category: 'local_tracking_stromma',
    actions: ['stromma_validation', 'stromma_click'],
  },
   {
    category: 'local_tracking_newsbundle',
    actions: ['close_banner', 'view_link'],
  },
  {
    category: 'local_tracking_floating',
    actions: ['close_banner', 'view_link'],
  },
  {
    category: 'stromma',
    actions: [
      'stromma_view_related_products',
      'stromma_click',
      'stromma_view_configurations',
      'stromma_conversion_funnel',
      'stromma_view_more_information',
      'stromma_view_faq',
    ],
  },
  {
    category: 'local_tracking_xmas_offers',
    actions: ['picture_click', 'link', 'arrow_click'],
  },
   {
    category: 'local_tracking_chatbot',
    actions: ['open_chatbot'],
  },
  {
    category: 'local_tracking_digital_showroom',
    actions: ['open_popup'],
  },
  {
    category: 'local_tracking_restaurant_menu',
    actions: [
      'menu_click',
      'filter_click',
      'pip_click',
    ],
  },
  {
    category: 'local_tracking_us_modal',
    actions: [
      'modal_open',
      'modal_close',
      'modal_submit_click',
      'modal_submit_success',
      'modal_submit_error',
    ],
  },
  //US
  {
    category: 'local_tracking_us_storefinder',
    actions: [
      'loc_click',
      'loc_get_directions_click',
      'visit_store_page',
      'make_my_store',
    ],
  },
  {
    category: 'local_tracking_us_filter',
    actions: [],
  },
   {
    category: 'local_tracking_finance_services_onboarding',
    actions: ['apply_for_fs'],
  },
  {
    category: 'local_tracking_us_sorting',
    actions: [],
  },
  {
    category: 'local_tracking_us_financeoptions',
    actions: [],
  },
  {
    category: 'local_tracking_us_filter_deselect',
    actions: [],
  },
  {
    category: 'local_tracking_us_eds',
    actions: [],
  },
  {
    category: 'local_tracking_profile_promotions',
    actions: ['join_club'],
  },
  {
    category: 'local_tracking_product_bundler',
    actions: ['add_to_cart','show'],
  },
  //CA
  {
    category: 'local_tracking_ikea_bundles',
    actions: [
      'landing_page',
      'bundle_chosen',
      'additional_kits',
      'swedish_treats',
      'surveys',
      'meatball_bundle',
      'sorry_to_see_you_go',
      'checkout_address',
      'food_subscription_profile',
      'checkout_payment',
      'unsubscribe_page',
      'thank_you',
    ],
  },
  //PL
  {
    category: 'local_tracking_complaint_form',
    actions: [
      'click',
      'begin_complaint_form1',
      'complaint_form1_completed',
      'begin_complaint_form2',
      'complaint_form2_completed',
      'search_complaints',
    ],
  },
  //ES
  {
    category: 'local_tracking_es_customer_type',
    actions: ['select_type'],
  },
  //PL
  {
    category: 'local_tracking_mobile_app',
    actions: ['download_by_appstore', 'download_by_googleplay'],
  },
  {
    category: 'local_tracking_engagedplusprepurchase_conversion',
    actions: [
      'engaged_conversion',
      'prepurchase_conversion',
    ],
  },
  {
    category: 'local_tracking_returns_form',
    actions: ['click'],
  },
  //NO
  {
    category: 'local_tracking_kindly_onsite',
    actions: ['click'],
  },

  //AU
  {
    category: 'local_tracking_au_appointment',
    actions: [
      'select_store',
      'select_stage',
      'select_appointment',
      'save_booking',
      'cancel_booking',
      'reschedule_booking',
    ],
  },
  {
    category: 'local_tracking_au_homesolar',
    actions: ['submit'],
  },
  {
    category: 'local_tracking_au_returns_comms',
    actions: ['content_interacted', 'visible',],
  },
  {
    category: 'local_tracking_au_countdown_timer',
    actions: ['shop_now','visible',],
  },
  {
    category: 'local_tracking_au_calendar_button',
    actions: ['add_to_calendar','visible',],
  },


  //PT
  {
    category: 'local_tracking_form',
    actions: ['pedidoadesao'],
  },

  //SE
  {
    category: 'local_tracking_planning_services',
    actions: ['click'],
  },
  {
    category: 'local_tracking_ikea_family_finance',
    actions: [
      'homepage_family_finance',
      'burger_click',
      'footer_click',
      'click_matercard',
      'apply_for_matercard',
      'family_finance_login',
      'family_finance_reister',
      'select_mastercard',
      'click',
    ],
  },
  {
    category: 'local_tracking_recheckit',
    actions: [
      'click',
      'popup_appears',
      'click_popup',
    ],
  },
  {
    category: 'local_tracking_ae_family_offer',
    actions: [
      'view_offer',
      'family_product_added',
      'view_join_banner',
      'click_join_banner',
    ],
  },
  {
    category: 'local_tracking_b2b_booking_tool',
    actions: [],
  },
  {
    category: 'local_tracking_fs_hub_onboarding',
    actions: ['apply_for_fs_hub',
              'visa_click_calculate',
              'visa_click_learnmore',
              'visa_click_apply',
              'business_click_calculate',
              'business_click_learnmore',
              'business_click_apply',
            ],
  },
  {
    category: 'local_tracking_fs_homepage',
    actions: ['visa_click','business_click'],
  },
  {
    category: 'local_tracking_us_ratings_reviews',
    actions: [
      'load_more_reviews_click',
      'sort_by_button_click',
      'sort_by_selection',
      'filter_by_dist_click',
      'filter_by_button_click',
      'filter_by_selection',
      'reviews_jump_link',
      'reviews_scroll_view',
      'read_more',
    ],
  },
  { category: 'dy_smart_object', actions: [] },
  { category: 'dy_smart_action', actions: [] },

  {
    category: 'ecommerce',
    actions: [
      'add_to_wishlist',
      'add_to_cart',
      'remove_from_wishlist',
      'remove_from_wishlist',
      'print_wishlist',
      'email_wishlist',
      'save_wishlist',
      'cross_off_checklist',
      'add_samuraj',
      'created_cart',
      'create_list',
      'rename_list',
      'remove_list',
      'wishlist_store_mode',
      'wishlist_online_mode',
      'wishlist_increase_amount',
      'wishlist_decrease_amount',
      'view_list',
      '3ds_iframe',
      'aci_card',
      'checkout_option',
      'checkout_progress',
      'set_checkout_option',
      'sign_up',
      'no_stock_web_order_purchase',
      'logged_in_foreign_address',
    ],
  },
  {
    category: 'cart',
    actions: [
      'open_login_modal',
      'alternative_item_found',
      'continue_as_member',
      'continue_as_guest',
      'cart_return_from_login',
      'cart_merge_conflict',
      'cart_merge_continue_to_checkout',
      'cart_open_eds',
      'cart_merge_add',
      'cart_merge_remove',
      'taskrabbit_zipin_readmore',
      'taskrabbit_product_readmore',
      'taskrabbit_modal_link_click',
      'taskrabbit_faq_assembly_availability',
      'taskrabbit_faq_included_assembly',
      'taskrabbit_faq_when_book',
      'taskrabbit_faq_when_pay',
      'taskrabbit_faq_pricing',
      'visit_pip',
      'open_alternative_item',
      'no_alternative_item_found',
      'cart_abandonment_modal_open',
      'cart_abandonment_modal_close',
      'cart_abandonment_modal_signup',
      'buy_now_displayed',
      'buy_now_clicked',
      'buy_now_error',
      'open_finance_page',
      'earliest_delivery_date_hd',
      'earliest_delivery_date_cc',
      'cart_missing_planner_items',
      'fs_cart_mp_click_openside',
      'fs_cart_sw_mp_click_openfp',
      'continue_to_checkout',
      'optimizely',
      'family_savings_login_promo',
      'family_savings_login_gateway',
      'empty_cart_confirmation_cancel',
      'empty_cart_confirmation_confirm',
      'services_entry_point_displayed',
      'accordion_click',
      'cart_promotional_modal_open',
      'cart_promotional_modal_signup',
      'cart_promotional_modal_close',
      'redirect_to_planner'
    ],
  },
  {
    category: 'taskrabbit_assembly',
    actions: ['pip', 'checkout'],
  },
  {
    category: 'customer_service',
    actions: [
      'selectedArticle',
      'readMoreArticle',
      'modalClosedBlur',
      'modalClosedCross',
      'navToAp',
      'apNotFound',
      'modalClosedButton',
      'feedback',
      'pageSwap',
      'vetaInfoReq',
      'spare_parts',
      'direct_partner_bank',
    ],
  },
  {
    category: 'loyalty_hub',
    actions: [
      'preferred_store',
      'little_man_slider',
      'assembly_documents',
      'assembly_service',
      'card_entrypoints',
      'events',
      'offers',
      'rewards',
      'key_balance_history',
      'key_earnings',
      'faq',
      're_consent',
      'prompt_add_phone_number',
      'prompt_verify_phone_number',
      'click_log_in',
      'cta_click',
    ],
  },
  {
    category: 'reward_detail',
    actions: [
      'reward_terms',
      'claim',
      'redeem',
      'breadcrumb',
    ],
  },
  {
    category: 'user_generated_content',
    actions: [
      'vugc_into_viewport',
      'vugc_out_of_viewport',
      'vugc_list_image_into_viewport',
      'vugc_detail_image_into_viewport',
      'vugc_open_detail',
      'vugc_close_detail',
      'vugc_list_images_end',
      'vugc_detail_images_end',
      'vugc_loaded',
      'vugc_tp_product_into_viewport',
      'vugc_tp_last',
      'vugc_tp_product_click',
      'vugc_add_to_cart',
      'vugc_add_to_wishlist',
      'vugc_load_more_into_viewport',
      'vugc_load_more_click',
      'vugc_page_entry_click',
      'vugc_like',
      'vugc_unlike',
      'rr_opened',
      'rr_closed',
      'rr_page_nav',
      'rr_modal_scrolled_to_bottom',
      'rr_loaded',
      'rr_lr_opened',
      'rr_lr_closed',
      'rr_lr_loaded',
      'rr_lr_auth',
      'rr_lr_failure',
      'rr_lr_transition',
      'rr_lr_mandatory',
      'rr_lr_validate',
      'rr_lr_success',
      'rr_filter_loaded',
      'rr_filter_clicked',
      'rr_sort_clicked',
      'rr_sort_opened',
      'rr_sort_closed',
      'rr_legal_notice_click',
      'rr_vote_add',
      'rr_vote_remove',
      'rr_order_scrolled_to_bottom',
      'rr_es_loaded',
      'rr_es_unsubscribed',
      'rr_es_resubscribed',
      'rr_es_failure',
      'rr_filter_lang_clicked',
      'rr_filter_lang_opened',
      'rr_filter_opened',
      'rr_filter_rating_opened',
      'rr_review_subrating_opened',
      'rr_sort_filter_opened',
      'qa_opened',
      'qa_ask_question_opened',
      'qa_guidelines_opened',
      'qa_guidelines_return',
      'qa_closed',
      'qa_ask_question_success',
      'qa_ask_question_click',
      'qa_ask_question_failure',
      'qa_page_nav',
      'qa_ask_question_return',
      'qa_modal_scrolled_to_bottom',
      'qa_privacy_policy_click',
      'qa_loaded',
      'qa_upvote',
      'qa_downvote',
      'rr_translate_clicked',
      'rr_original_clicked',
      'rr_verified_icon_clicked',
      'rr_local_clicked',
      'rr_other_clicked',
      'rr_load_more',
      'qa_search_submit',
      'qa_search_submit_results',
      'qa_search_reset',
      'qa_all_viewed',
      'qa_search_sort',
   ],
  },
  // CA
  {
    category: 'local_tracking_homepage_ca',
    actions: [],
  },
  // no
  {
    category: 'local_tracking_add_on',
    actions: ['view', 'slide_click', 'slide_change', 'add_samuraj'],
  },
  // se
  {
    category: 'local_tracking_proactive_chat',
    actions: ['click'],
  },
  // cn
  {
    category: 'local_tracking_sofa',
    actions: ['click'],
  },
  {
    category: 'local_tracking_pip_back_in_stock',
    actions: ['subscribe_stock_notification'],
  },
  {
    category: 'local_tracking_combo_bundler_ca',
    actions: [
      'pip_bundle_impression',
      'pip_add_bundle_to_cart',
      'landing_bundle_impression',
      'landing_add_bundle_to_cart',
    ],
  },
  {
    category: 'local_tracking_anchor_links',
    actions: ['click'],
  },
  {
    category: 'local_tracking_rtb_house',
    actions: ['page_load'],
  },
  {
    category: 'local_tracking_fabric_sample',
    actions: [
      'link_click',
      'sample_image_click',
      'form_submit',
      'input_changed',
    ],
  },
  //FI
  {
    category: 'local_tracking_giosglive',
    actions: [
      'chatbutton',
      'autosuggested',
      'hasChatted',
      'autosuggestedHasChatted',
      'leadFormWasShown',
      'leadFormWasCompleted',
      'visitorReachedGoal',
      'hasChatted',
    ],
  },
  {
    category: 'local_tracking_time_center',
    actions: ['link_click'],
  },
  {
    category: 'local_tracking_sticky_fix',
    actions: ['view', 'hidden', 'dismissed'],
  },
  {
    category: 'local_tracking_flooring_calc',
    actions: ['show', 'length_changed', 'width_changed', 'calculate',],
  },
  {
    category: 'local_tracking_planner_links',
    actions: ['click'],
  },
  {
    category: 'local_tracking_sidecart',
    actions: ['open', 'edit', 'delete', 'change', 'go_to_cart', 'close'],
  },
  {
    category: 'local_tracking_banner',
    actions: ['click'],
  },
  // de
  { category: 'local_tracking_filter', actions: [] },
  { category: 'local_tracking_user_actions', actions: [] },
  { category: 'local_tracking_navigation', actions: [] },
  // ch
  { category: 'local_tracking_cta', actions: [] },
  // pl
  {
    category: 'local_tracking_tool_pax_web',
    actions: [
      'click_arrow_left',
      'click_arrow_right',
      'click_reset',
      'click_close',
      'send',
      'click',
      'add_to_cart',
      'add_to_list',
      'click_confirm',
      'click_select',
    ],
  },
  {
    category: 'local_tracking_tool_pax_dmp',
    actions: [
      'click_arrow_left',
      'click_arrow_right',
      'click_reset',
      'click_close',
      'send',
      'click',
      'add_to_cart',
      'add_to_list',
      'click_confirm',
      'click_select',
    ],
  },
  {
    category: 'local_tracking_tool_materace_web',
    actions: [
      'click_confirm',
      'selected_body',
      'selected_position',
      'selected_temp',
      'selected_allergy',
      'send',
      'add_to_list',
      'add_to_cart',
      'know_more',
    ],
  },
  {
    category: 'local_tracking_tool_materace_dmp',
    actions: [
      'click_confirm',
      'selected_body',
      'selected_position',
      'selected_temp',
      'selected_allergy',
      'send',
      'add_to_list',
      'add_to_cart',
      'know_more',
    ],
  },
  {
    category: 'local_tracking_us_taskrabbit',
    actions: [
      'tr_learn_open',
      'tr_learn_close',
      'tr_learn_availability',
      'tr_learn_included',
      'tr_learn_book',
      'tr_learn_pay',
      'tr_learn_price',
    ],
  },
  {
    category: 'business_profile',
    actions: [
      'delete_business',
      'downgrade_business_network',
      'upgrade_business_network',
      'change_password',
      'invite_contact_person',
      'download_digital_card',
      'inline_data_capture_open',
      'inline_data_capture_close',
      'inline_data_capture_save',
      'inline_data_capture_next',
      'inline_data_capture_finished',
      'inline_data_capture_show_notice',
      'view',
      'click',
      'feedback',
    ],
  },
  {
    category: 'local_tracking_us_returns_flow',
    actions: ['form_validation_error'],
  },
  // se
  {
    category: 'local_tracking_pip_page_actions',
    actions: [
      'pip_usp_click',
      'popup_recommendations',
      'custom_work_tops_click',
      'store_check',
      'delivery_availability_message_click',
      'media_dot_hover'
    ],
  },
  {
    category: 'Kaltura Video Events',
    actions: [],
  },
  {
    category: 'local_tracking_video',
    actions: [],
  },
  {
    category: 'local_tracking_kitchen_booking_tool',
    actions: [],
  },
  //CZ/HU/SK
  {
    category: 'local_tracking_booking_tool',
    actions: [
      'start_reservation',
      'planning_location',
      'planning_in_person',
      'planning_date_selection',
      'detailed_information',
      'order_submit',
      'finished_booking',
    ],
  },
  {
    category: 'local_tracking_customer_service',
    actions: ['successful_complaint_request', 'unsuccessful_complaint_request','login','skip'],
  },
  // nl
  {
    category: 'local_tracking_solar_panels',
    actions: [
      'select_package',
      'upgrade_panel',
      'battery_storage',
      'play_video',
      'open_faq',
      'book_consultation',
      'confirm_booking',
      'back_button',
      'click',
      'lead_creation',
      'solar_click',
      'select_configuration',
      'view_options',
      'form_submission',
      'internal_click',
    ],
  },
  // ae
  {
    category: 'local_tracking_contact_us',
    actions: ['send_message', 'link'],
  },
  // sg
  {
    category: 'local_tracking_engage',
    actions: ['swipe', 'view_section','close_banner','view_link'],
  },
  {
    category: 'local_tracking_first_visit_banner',
    actions: [],
  },
  //dev events
  {
    category: 'recommendations_event',
    actions: [],
  },
  {
    category: 'business',
    actions: [
      'call_us',
      'finance_support_1',
      'consultancy_forus',
      'consultancy_ringsaker',
      'consultancy_sorlandet',
      'request_form_contact_us',
      'apply_businessaccount',
      'finance_support_2',
    ],
  },
  {
    category: 'watch_ikea',
    actions: [
      'youtube_video_play',
      'bambuser_video_play',
      'youtube_video_pause',
      'youtube_video_end',
      'bambuser_video_end',
      'youtube_video_open_shopping_list',
      'bambuser_video_pause',
    ],
  },

  //US
  {
    category: 'local_tracking_ext_us',
    actions: ['buy_the_set', 'see_details', 'popup_by_online', 'buy_online'],
  },
  {
    category: 'local_tracking_ikearenocations',
    actions: ['quiz', 'click'],
  },
  {
    category: 'local_tracking_qrvideos',
    actions: [
      'view',
      'view_007',
      'view_091',
      'view_095',
      'view_126',
      'view_371',
      'view_390',
      'view_441',
    ],
  },
  {
    category: 'local_content_engagement',
    actions: [
      'egift_choose_template',
      'joinus_popup',
      'joinus_close',
      'error_notice',
      'video_open',
      'video_close',
      'video_play',
      'slider_banners_seen',
      'slider_arrow_left',
      'slider_arrow_right',
    ],
  },
  {
    category: 'local_customer_service',
    actions: [
      'delivery_zipcode_check',
      'delivery_order_status',
      'contact_search',
      'contact_topic',
      'contact_faqs',
      'contact_faqs_like',
      'contact_faqs_dislike',
      'contact_form_send',
      'order_info_click',
      'services_click',
      'return_click',
      'pages_click',
      'stores_click',
      'contact_us_click',
      'info_popup_click',
    ],
  },
  {
    category: 'local_tracking_leadtime',
    actions: ['calculate'],
  },
  //US
  {
    category: 'local_tracking_us_checkout',
    actions: [
      'sc_cod_acknowledge_displayed',
      'sc_cod_acknowledge_check',
      'sc_cod_acknowledge_error',
      'sc_pup_select',
      'sc_pup_fedex',
      'sc_pup_fedex_loc_default',
      'sc_pup_fedex_loc_final',
      'sc_pup_fedex_date_default',
      'sc_pup_fedex_date_final',
      'sc_delivery_to_shipping_fedex',
      'sc_delivery_to_shipping_store',
      'sc_delivery_to_shipping_home',
      'sc_family_modal_open',
      'sc_family_modal_close',
      'sc_family_navigate',
    ],
  },
  {
    category: 'local_tracking_us_campaign',
    actions: [
      'he_open_filter',
      'he_select_price',
      'he_select_category',
      'he_close_filter',
    ],
  },
  {
    category: 'local_tracking_us_geolocation',
    actions: [
      'loc_prompt_home',
      'loc_prompt_pip',
      'loc_prompt_taskrabbit',
      'loc_allow_home',
      'loc_allow_pip',
      'loc_allow_taskrabbit',
      'loc_block_home',
      'loc_block_pip',
      'loc_block_taskrabbit',
      'tr_zip_autofill',
      'tr_zip_autofill_change',
      'new_loc_prompt',
      'new_loc_prompt_approved',
      'new_loc_prompt_denied',
      'new_loc_prompt_closed',
      'use_my_loc_click',
      'use_my_loc_prompt',
      'use_my_loc_approved',
      'use_my_loc_denied',
      'loc_search_result',
      'loc_favorite_store',
      'loc_directions_click',
      'loc_store_page_click',
      'loc_nav_click',
      'loc_get_user_ip',
      'loc_no_user_ip',
      'delivery_use_my_loc_click',
      'delivery_use_my_loc_prompt',
      'delivery_use_my_loc_approved',
      'delivery_use_my_loc_denied',
      'delivery_loc_nav_click',
      'delivery_zip_submission',
      'loc_outside_radius',
      'hours_expander_click',
      'api_error_google_geocode',
      'api_error_google_reverse_geocode',
      'api_error_google_dist_matrix',
      'api_error_ip',
      'api_error_postalcode_validation',
      'api_error_navigera',
      'delivery_zip_change',
    ],
  },
  {
    category: 'local_tracking_scroll',
    actions: ['scroll_depth'],
  },
  {
    category: 'local_tracking_kitchen_planning',
    actions: [
      'confirm_zipcode',
      'make_appointment',
      'dimensioning',
      'date',
      'change',
      'buy_plan',
    ],
  },
  {
    category: 'local_tracking_time_on_page',
    actions: ['timer'],
  },
  {
    category: 'local_tracking_quality_user',
    actions: [],
  },
  {
    category: 'local_tracking_us_webchat',
    actions: [],
  },
  //ES
  {
    category: 'local_tracking_product_shelves',
    actions: ['click'],
  },
  {
    category: 'local_conversion_path',
    actions: [
      'egift_purchase',
      'egift_confirm',
      'egift_pay',
      'egift_thank_you',
      'reco_preview_product',
      'joinus_register',
      'joinus_login',
      'lead_form_first',
      'lead_form_second',
      'lead_form_third',
    ],
  },
  {
    category: 'local_ikea_business_page',
    actions: ['main_page_click'],
  },
  {
    category: 'local_tracking_statuspage',
    actions: ['click'],
  },
  {
    category: 'local_tracking_onsite_personalization',
    actions: [],
  },
  {
    category: 'local_tracking_homepage_areas',
    actions: ['clicks_on_mpa', 'clicks_on_spa'],
  },
  //CA
  {
    category: 'local_tracking_services_furniture_bank',
    actions: [
      'read_more',
      'check_availability',
      'check_availability_pip',
      'book_appointment_pip',
      'check_availability_checkout',
      'book_appointment_checkout',
    ],
  },
  {
    category: 'local_tracking_hfa_page_actions',
    actions: [],
  },
  {
    category: 'local_tracking_homepage_areas',
    actions: ['clicks_on_mpa', 'clicks_on_spa'],
  },
  {
    category: 'local_tracking_shopping_bag',
    actions: [
      'view_item_list',
      'stock_check_failed',
      'add_to_cart',
      'cart_stock_refresh',
      'remove_from_cart',
      'no_similar_products',
      'selected_store',
      'stock_check',
    ],
  },
  {
    category: 'local_tracking_wait_time',
    actions: ['cick_date', 'cick_hour'],
  },
  //UK/ES
  {
    category: 'ikea_business_club',
    actions: [
      'ikea_benefits',
      'business_handraiser_form',
      'ikea_business_signup',
    ],
  },
  //CZ/HU/SK M2 Site
  {
    category: 'local_tracking_user_login',
    actions: ['logins_IKEA_family_hashed'],
  },
  {
    category: 'local_tracking_social_media',
    actions: ['sharing'],
  },
  {
    category: 'local_tracking_au_ai_gift_recommendations',
    actions: ['form_visible',
              'form_start',
              'form_step',
              'form_submitted',
              'results_from_api',
              'results_visible',
              'product_cart_clicked',
              'product_wishlist_clicked',
              'form_retry',
              'feedback_recommendation',
              'product_link_clicked',
    ],
  },
  {
    category: 'local_tracking_10pd_session',
    actions: ['view'],
  },
  {
    category: 'local_tracking_freemarketmessage',
    actions: ['link_clicks'],
  },
  {
    category: 'local_tracking_fr_zipin',
    actions: ['check'],
  },
  {
    category: 'local_tracking_kiosk_family',
    actions: [
      'click',
      'kiosk_family_signup',
      'search_for_membership',
      'kiosk_family_signup_begun',
      'kiosk_family_signup_completed',
      'reminder_popup',
    ],
  },
  {
    category: 'local_tracking_sa_secondscreen',
    actions: [
      'time_on_page',
    ],
  },
  {
    category: 'local_inspo_bundler',
    actions: [
      'campaign_add_bundle_to_cart',
      'landing_click_product_link'
    ],
  },
  {
    category: 'sockbot',
    actions: [
      'question1',
      'question2',
      'question3',
      'question3b',
      'question4',
      'question4b',
      'question5',
      'question6',
    ],
  },
  {
    category: 'product_presentation_xr_actions',
    actions: [
      'add_to_cart_3d_available',
      'add_to_cart_after_3d_viewer',
      'add_to_cart_after_ar_view',
      'add_to_favourites_3d_available',
      'add_to_favourites_after_3d_viewer',
      'add_to_favourites_after_ar_view',
      'ar_view_open',
      'ar_view_close',
      'stock_check_3d_available',
      'stock_check_after_3d_viewer',
      'stock_check_after_ar_view',
      'viewer_add_button',
      'viewer_close',
      'viewer_behaviour_view_product_top',
      'viewer_behaviour_view_product_back',
      'viewer_behaviour_view_product_bottom',
      'viewer_engagement_5_seconds',
      'viewer_engagement_10_seconds',
      'viewer_engagement_15_seconds',
      'viewer_engagement_20_seconds',
      'viewer_engagement_25_seconds',
      'viewer_engagement_30_seconds',
      'viewer_interact',
      'viewer_open',
      'viewer_remove_button',
      'viewer_open_measurements_modal',
      'viewer_open_main_button',
    ],
  },
  {
    category: 'expr_actions',
    actions: [
      'generic',
    ],
  },
  {
    category: 'order_capture',
    actions: ['optimizely'],
  },
  {
    category: 'design_lab_actions',
    actions: [
      'create_room_event',
      'dashboard_capture_own_room',
      'dashboard_carousel_open_room',
      'dashboard_carousel_view_slide',
      'dashboard_composition_create_from_premade',
      'dashboard_composition_create_from_empty',
      'dashboard_composition_create_from_showroom',
      'dashboard_composition_create_from_own_room',
      'dashboard_composition_delete',
      'dashboard_composition_navigate',
      'dashboard_composition_place_furniture',
      'dashboard_composition_rename',
      'dashboard_composition_save',
      'dashboard_composition_share',
      'dashboard_create_room_event',
      'dashboard_magic_window_close',
      'dashboard_magic_window_open',
      'dashboard_magic_window_time_spent',
      'dashboard_navigate_cart',
      'dashboard_navigate_favourites',
      'dashboard_navigate_login',
      'dashboard_navigate_planners',
      'dashboard_navigate_profile',
      'dashboard_navigate_room',
      'dashboard_navigate_style_quiz',
      'dashboard_navigation_event',
      'dashboard_room_grid_expand_button_click',
      'dashboard_room_grid_open_room',
      'error_internal',
      'error_external',
      'generic_expand_area',
      'generic_collapse_area',
      'generic_view_area',
      'generic_progress_step',
      'geo_create_room',
      'geo_save_room',
      'intro_page_event',
      'intro_page_carousel_open_room',
      'intro_page_carousel_view_slide',
      'navigation_event',
      'room_details_event',
      'room_page_composition_place_furniture',
      'room_page_composition_rename',
      'room_page_composition_save',
      'room_page_composition_share',
      'room_page_navigate_dashboard',
      'room_page_navigate_login',
      'room_placeholder_event',
      'style_quiz_event',
      'survey_rating',
      'survey_thumbs',
      'survey_thumbs_up',
      'survey_thumbs_down',
      'user_first_impression',
      'user_return',
      'view_page',
      'create-room',
      'customer-feedback',
      'delete-saved-room',
      'faq',
      'navigation',
      'open-room',
      'open-saved-room',
      'page-view',
      'rename-saved-room',
      'room-page',
      'sign-in',
      'style-quiz-event',
      'hero_banner_click',
      'showroom_banner_click',
      'ownroom_banner_click',
      'ikea_app_banner_click',

    ],
  },
  {
    category: 'hri_homepage',
    actions: [
      'hri-fragments-hero-in-viewport',
      'hri-fragments-hero-cta-click',
      'hri-fragments-hero-image-click',
      'hri-fragments-hero-carousel-right',
      'hri-fragments-hero-carousel-left',
      'hri-fragments-hero-carousel-slide-change',
      'hri-fragments-hero-dot-click',
      'hri-fragments-hero-product-click',
      'hri-fragments-popular-rooms-in-viewport',
      'hri-fragments-popular-rooms-click',
      'hri-fragments-popular-rooms-carousel-right',
      'hri-fragments-popular-rooms-carousel-left',
      'hri-fragments-popular-categories-in-viewport',
      'hri-fragments-popular-categories-click',
      'hri-fragments-popular-categories-carousel-right',
      'hri-fragments-popular-categories-carousel-left',
      'hri-fragments-offers-in-viewport',
      'hri-fragments-offers-filter-click',
      'hri-fragments-offers-card-click',
      'hri-fragments-offers-product-click',
      'hri-fragments-offers-carousel-right',
      'hri-fragments-offers-carousel-left',
      'hri-fragments-store-in-viewport',
      'hri-fragments-store-event-header-click',
      'hri-fragments-store-event-card-click',
      'hri-fragments-store-event-modal-click',
      'hri-fragments-store-events-carousel-right',
      'hri-fragments-store-events-carousel-left',
      'hri-fragments-store-events-viewed',
      'hri-fragments-page-composition-actual',
      'hri-fragments-page-composition-predicted',
    ],
  },
  {
    category: 'ufx_introducera',
    actions: [
      'ufx-fragments-hero-in-viewport',
      'ufx-fragments-hero-cta-click',
      'ufx-fragments-hero-product-click',
      'ufx-fragments-hero-dot-click',
      'ufx-fragments-rooms-shelf-in-viewport',
      'ufx-fragments-rooms-shelf-click',
      'ufx-fragments-rooms-shelf-carousel-left',
      'ufx-fragments-rooms-shelf-carousel-right',
      'ufx-fragments-categories-nav-in-viewport',
      'ufx-fragments-categories-nav-click',
      'ufx-fragments-categories-nav-carousel-left',
      'ufx-fragments-categories-nav-carousel-right',
      'ufx-fragments-new-recently-viewed-products-item-click',
      'ufx-fragments-new-recently-viewed-products-in-viewport',
      'ufx-fragments-new-recently-viewed-products-gallery-up',
      'ufx-fragments-new-recently-viewed-products-gallery-down',
      'ufx-fragments-new-recently-viewed-products-carousel-right',
      'ufx-fragments-new-recently-viewed-products-carousel-left',
      'ufx-fragments-hero-carousel-left',
      'ufx-fragments-hero-carousel-right',
      'ufx-fragments-hero-carousel-slide-change',
      'ufx-fragments-hero-image-click',
      'ufx-fragments-tiles-in-viewport',
      'ufx-fragments-tile-click',
      'ufx-fragments-tiles-carousel-left',
      'ufx-fragments-tiles-carousel-right',
      'ufx-fragments-offers-in-viewport',
      'ufx-fragments-offers-carousel-left',
      'ufx-fragments-offers-carousel-right',
      'ufx-fragments-offers-filter-click',
      'ufx-fragments-offer-product-click',
      'ufx-fragments-offer-card-click',
    ],
  },
  {
    category: 'edc-fragments',
    actions: [
      'component-in-viewport',
      'cta_click',
      'image_click',
      'title_click',
      'pricetag_click',
    ],
  },
  // ROIG markets mvc checkout events from GTM
  {
    category: 'delivery_opt_available',
    actions: [
      'deliveryOptAvailable',
    ],
  },
  {
    category: 'delivery_opt_select',
    actions: [
      'delivery_selected',
    ],
  },
  {
    category: 'delivery_time_select',
    actions: [
      'delivery_time_select'
    ],
  },
  {
    category: 'form_submit',
    actions: [
      'detailsform',
      'deliveryform',
    ],
  },
  {
    category: 'feedback_rating',
    actions: [
      'feedback_rating',
    ],
  },
  {
    category: 'payment',
    actions: [
      'payment_selected',
      'paymentsubmit',
      'paymentsuccess',
      'paymenterror',
      'payment_payment_selected',
      'payment_paymentsubmit',
      'payment_paymentsuccess',
      'payment_paymenterror',
    ],
  },
  {
    category: 'availability_check',
    actions: [
      'availability_check',
    ],
  },
  {
    category: 'item_availability_check',
    actions: [
      'all items are available',
      'some items are not available',
      'none items are available',
    ],
  },
  {
    category: 'payment_opt_available',
    actions: [
      'creditcard',
      'ipp',
      'promptpayqr',
      'cmrcard',
      'debitcard',
      'gcash',
      'bnpl',
      'fpx',
      'enets',
      'paypal',
      'knet',
      'walletqr',
      'atome',
      'payment_available'
    ],
  },
  // NGA4 events
  {
    category: 'clicks',
    actions: [
      'clickandcollect',
      'product_summary_hover',
      'product_summary_clicked',
      'phone_country_code',
      'rage_click',
      'faq',
      'clicks_on_data',
      'click_and_collect_map'
    ],
  },
  {
    category: 'pinpoint_address',
    actions: [
      'pinpoint_address',
    ],
  },
  {
    category: 'optimizely_value',
    actions: [
      'optimizely_value',
    ],
  },
  {
    category: 'known_customer_journey',
    actions: [
      'known_customer_journey',
    ],
  },
  {
    category: 'hover',
    actions: [
      'topNav',
    ],
  },
  {
    category: 'exit_form_field',
    actions: [
      'exit_form_field',
    ],
  },
  {
    category: 'pup_location',
    actions: [
      'pup_available',
      'pup_selected'
    ],
  },
  {
    category: 'live_chat',
    actions: [
      'live_chat',
    ],
  },
  {
    category: 'core_web_vitals',
    actions: [
      'cls',
      'inp',
      'lcp',
    ],
  },
];

export const allowedEcomEvents = [
  {
    name: 'select_items',
    parameters: ['items'],
  },
  {
    name: 'view_item_list',
    parameters: ['items'],
  },
  {
    name: 'select_content',
    parameters: ['items', 'promotions', 'content_type', 'content_id'],
  },
  {
    name: 'view_item',
    parameters: ['items'],
  },
  {
    name: 'view_promotion',
    parameters: ['promotions'],
  },
  {
    name: 'add_to_cart',
    parameters: ['value', 'currency', 'items'],
  },
  {
    name: 'add_to_wishlist',
    parameters: ['items'],
  },
  {
    name: 'remove_from_wishlist',
    parameters: ['items'],
  },
  {
    name: 'add_samuraj',
    parameters: ['value', 'currency', 'items'],
  },
  {
    name: 'remove_from_cart',
    parameters: ['value', 'currency', 'items'],
  },
  {
    name: 'begin_checkout',
    parameters: ['value', 'currency', 'items', 'coupon'],
  },
  {
    name: 'checkout_progress',
    parameters: [
      'value',
      'currency',
      'items',
      'coupon',
      'checkout_step',
      'checkout_option',
    ],
  },
  {
    name: 'set_checkout_option',
    parameters: ['checkout_step', 'checkout_option'],
  },
  {
    name: 'purchase',
    parameters: [
      'transaction_id',
      'affiliation',
      'value',
      'currency',
      'tax',
      'shipping',
      'items',
      'coupon',
      'list_name',
      'checkout_step',
      'option',
    ],
    requiredParameters: ['transaction_id'],
  },
  {
    name: 'refund',
    parameters: [
      'transaction_id',
      'affiliation',
      'value',
      'currency',
      'tax',
      'shipping',
      'items',
      'list_name',
      'checkout_step',
      'option',
    ],
    requiredParameters: ['transaction_id'],
  },
  {
    name: 'promo_click',
    parameters: ['id', 'name', 'creative_name', 'list_position'],
  },
];

export const requiredEcomEventProperties = [
  { parameter: 'items', properties: ['id'] },
  { parameter: 'promotions', properties: ['id'] },
];

export let allowedCustomDimensions = {
  dimension1: 'site_language',
  dimension2: 'site_market',
  dimension3: 'site_platform',
  dimension4: 'version',
  dimension5: 'user_id',
  dimension6: 'page_type',
  dimension7: 'page_category',
  dimension8: 'local_store',
  dimension9: 'postal_code',
  dimension10: 'stock_check_result',
  dimension11: 'survey_and_response',
  dimension12: 'cart_id',
  dimension13: 'calculated_delivery_days',
  dimension14: 'offered_delivery_days',
  dimension15: 'technical_component',
  dimension16: 'navigation_type',
  dimension17: 'internal_search_hits',
  dimension18: 'planner',
  dimension19: 'query_parameter',
  dimension23: 'user_agent',
  dimension24: 'search_type',
  dimension25: 'local_currency_code',
  dimension27: 'payment_method',
  dimension28: 'order_type',
  dimension29: 'delivery_method',
  dimension30: 'fulfilled_by',
  dimension31: 'team',
  dimension32: 'service_area_id',
  dimension33: 'dimension33',
  dimension34: 'dimension34',
  dimension35: 'dimension35',
  dimension37: 'product_list_variant',
  dimension38: 'chat_trigger_rule',
  dimension39: 'stock_check_store_id',
  dimension41: 'item_number_event',
  dimension42: 'product_price_class',
  dimension43: 'product_style_group',
  dimension44: 'itm_element',
  dimension45: 'itm_content',
  dimension46: 'itm_campaign',
  dimension47: 'planning_id',
  dimension48: 'clientId',
  dimension49: 'sessionID',
  dimension51: 'removal_services_available',
  dimension52: 'removal_service_cart_value',
  dimension53: 'dimension53',
  dimension54: 'dimension54',
  dimension55: 'dimension55',
  dimension56: 'additional_search_info',
  dimension57: 'cart_value',
  dimension58: 'item_type_event',
  dimension59: 'navigation_from',
  dimension65: 'navigation_link',
  dimension66: 'internal_search_term',
  dimension67: 'pick_up_point_location',
  dimension72: 'error_id',
  dimension73: 'position',
  dimension74: 'adform_line_item_id',
  dimension75: 'adform_cookie_id',
  dimension76: 'assembly_selected',
  dimension77: 'previous_page_referrer',
  dimension78: 'full_url',
  dimension79: 'performance_cookies',
  dimension80: 'functional_cookies',
  dimension81: 'targeting_cookies',
  dimension82: 'social_media_cookies',
  dimension83: 'error_message',
  dimension84: 'error_code',
  dimension88: 'sfmc_id',
  dimension89: 'emp_filter',
  dimension90: 'error_text',
  dimension91: 'algorithm',
  dimension92: 'error_type',
  dimension93: 'error_category',
  dimension95: 'error_api',
  dimension96: 'error_cause',
  dimension97: 'error_status_code',
  dimension98: 'api_errors_preceding',
  dimension99: 'product_image_id',
  dimension100: 'propensity',
  dimension101: 'component_id',
  dimension102: 'component_type',
  dimension103: 'item_index',
  dimension104: 'users_local_time',
  dimension105: 'video_user_identifier',
  dimension106: 'kiosk_id',
  dimension107: 'kiosk_location',
  dimension108: 'store_solution',
  dimension109: 'receiving_solution',
  dimension110: 'navigation_guide',
  dimension111: 'user_type',
  dimension112: 'cart_quantity',
  dimension113: 'service_days_offered',
  dimension114: 'service_days_selected',
  dimension115: 'service_days_time_slot_selected',
  dimension116: 'filter_parameters',
  dimension117: 'order_id',
  dimension118: 'order_type_detailed',
  dimension119: 'order_type_product',
  dimension120: 'sales_method',
  dimension121: 'selected_delivery_days',
  dimension122: 'list_id',
  dimension123: 'delivery_cost',
  dimension124: 'delivery_time_slot',
  dimension125: 'service_cart_value',
  dimension126: 'product_quantity',
  dimension127: 'delivery_arrangement_id',
  dimension128: 'logged_in',
  dimension129: 'services_available',
  dimension130: 'csMatchingKey',
  dimension131: 'feed_tag_combinations',
  dimension132: 'checkout_capabilities',
  dimension133: 'return_method',
  dimension134: 'return_label_delivery_method',
  dimension135: 'refund_option',
  dimension136: 'customer_type',
  dimension137: 'store_availability',
  dimension138: 'delivery_availability',
  dimension139: 'collect_availability',
  dimension140: 'available_quantity',
  dimension141: 'nps_score',
  dimension142: 'nps_comment',
  dimension143: 'nps_feedback',
  dimension144: 'page_id',
  dimension145: 'has_lite_customer_id',
  dimension146: 'delivery_recommendation',
  dimension147: 'assembly_associated_item',
  dimension148: 'list_value',
  dimension149: 'chat_interaction_id',
  dimension167: 'consumer_name',
  dimension168: 'checkout_type',
  dimension169: 'custom_segment',
  dimension170: 'store_availability_hit',
  dimension171: 'delivery_availability_hit',
  dimension172: 'collect_availability_hit',
  dimension173: 'assembly_availability_hit',
  dimension174: 'checkout_id',
  dimension175: 'page_message_text',
  dimension176: 'product_model',
  dimension177: 'fabric_id',
  dimension178: 'sales_steering_tag',
  dimension179: 'ga_client_id',
  dimension180: 'profitability_tag',
};

export let allowedCustomMetrics = {
  metric1: 'number_of_internal_campaign_clicks',
  metric2: 'number_of_internal_searches',
  metric3: 'calculated_delivery_number_of_calculations',
  metric4: 'calculated_delivery_number_of_days',
  metric5: 'offered_delivery_number_of_calculations',
  metric6: 'offered_delivery_number_of_days',
  metric7: 'global_revenue',
  metric8: 'global_vat',
  metric9: 'global_shipping',
  metric10: 'cart_value_euro',
  metric11: 'cart_value_local',
  metric12: 'discount_value',
  metric13: 'ratings_value',
  metric14: 'zero_search_results',
  metric15: 'number_of_ratings',
  metric16: 'list_click_position',
  metric17: 'response_score',
  metric18: 'number_of_responses',
  metric19: 'cart_number_of_items',
  metric20: 'discount_value_eur',
  metric21: 'survey_views',
  metric22: 'product_buyable_online',
  metric23: 'product_not_buyable_online',
  metric24: 'stock_check_number_of_stock_checks',
  metric25: 'stock_check_number_of_out_of_stock_results',
  metric26: 'adds_to_wishlist',
  metric27: 'offered_chat',
  metric28: 'accepted_chat',
  metric29: 'ratings_views',
  metric30: 'assembly_available',
  metric31: 'assembly_purchased_number_of_times',
  metric32: 'assembly_price',
  metric33: 'navigation_timing',
  metric34: 'cart_quantity_metric',
  metric35: 'available_for_delivery',
  metric36: 'not_available_for_delivery',
  metric37: 'number_of_questions',
};

for (let i = 180; i <= 200; i++) {
  allowedCustomDimensions[`dimension${i}`] = `local_dimension${i}`;
  allowedCustomMetrics[`metric${i}`] = `local_metric${i}`;
}

// These dimensions are MAGIC and must be included as custom_map
// Eg some are set in the ga-event just due to their name
export let requiredCustomDimensions = {
  dimension48: 'clientId',
};

/**
 * Swaps the keys and values.
 *
 * From:
 * ```
 * {A: 1, B: 2}
 * ```
 *
 * To:
 * ```
 * {1: A, 2: B}
 * ```
 */
function swapKeysAndValues(sourceObject) {
  var ret = {};
  for (var key in sourceObject) {
    ret[sourceObject[key]] = key;
  }
  return ret;
}

/**
 * Returns a result where keys and values has been swapped.
 */
export let allowedCustomDimensionsSwapped = swapKeysAndValues(
  allowedCustomDimensions
);

/**
 * Returns a result where keys and values has been swapped.
 */
export let allowedCustomMetricsSwapped =
  swapKeysAndValues(allowedCustomMetrics);

export let combinedDimensionsAndMetricsSwapped = Object.assign(
  {},
  allowedCustomDimensionsSwapped,
  allowedCustomMetricsSwapped
);
