import { sendEvent } from '@ingka-group-digital/episod-client-js';

import {
  country as constantsCountry,
  language as constantsLanguage,
  inhouseMarkets,
} from '../constants';

import {
  getMultipleEventObjects,
  convertGAtoEpisod,
} from './helpers';

export const dispatch = (eventObj = {}, { initialPageView = false, PROD, country: optionsCountry, language: optionLanguage}) => {
  try {
    const country = optionsCountry || constantsCountry;
    const language = optionLanguage || constantsLanguage;

    if (!inhouseMarkets.includes(country)) {
      return;
    }

    const eventObjects = getMultipleEventObjects(eventObj);

    for (const eventObject of eventObjects) {
      const payload = convertGAtoEpisod(eventObject, { initialPageView, PROD, country, language });

      if (payload.event_name.includes('optimizely')) {
        return;
      }

      sendEvent(payload);
    }
  } catch (error) {
    console.error('Error in Episod dispatch', error);
  }
};
