import {
  allowedEvents,
  allowedEcomEvents,
  requiredEcomEventProperties,
  allowedCustomDimensions,
  allowedCustomMetrics,
} from './whitelist';
import { country, ga4TrackingIds, gtmTrackingIds, language, PROD } from './constants';

const errorPrefix = 'GA error:';
let sessionId = new Date().getTime() + '.' + Math.random().toString(36).substring(5);

const getNavigationData = () => {
  if (sessionStorage['navLinkClick']) {
    const { pageUrl, link, navType, timing, siblings = 0 } = JSON.parse(
      sessionStorage.getItem('navLinkClick')
    );
    const data = {
      navigation_from: pageUrl,
      navigation_link: link,
      navigation_type: navType,
      navigation_timing: timing,
      navigation_size: siblings,
    };
    sessionStorage.removeItem('navLinkClick');
    return Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null && v !== ''));
  }

  return null;
}

const navigationData = getNavigationData();

export const getSessionID = () => sessionId;

export const setSessionID = () => sessionId = new Date().getTime() + '.' + Math.random().toString(36).substring(5);;

export const eventLogger = ({
  event_category,
  event_action,
  event_label = '',
  name,
  params,
  custom = {},
  transport_type = '',
}) => {
  if (event_category) {
    console.log('event', event_category, {
      event_action,
      event_label,
      ...custom,
      transport_type,
    });
  } else if (name) {
    console.log('ecomEvent', name, { ...params, ...custom, transport_type });
  } else {
    console.log(eventObj);
  }
};

export const verifyEvent = (category, action, custom = {}) => {
  if (!category) {
    throw `${errorPrefix} event_category can not be empty`;
  }
  const matchedEvent = allowedEvents.find((list) => list.category === category);
  if (!matchedEvent) {
    throw `${errorPrefix} event_category, "${category}", is not supported`;
  }
  if (matchedEvent.actions.length && !action) {
    throw `${errorPrefix} event_action can not be empty`;
  }
  if (matchedEvent.actions.length && !matchedEvent.actions.includes(action)) {
    throw `${errorPrefix} event_action, "${action}" is not supported`;
  }
  return;
};

export const verifyEcomEvent = (name, params, custom = {}) => {
  if (!name) {
    throw `${errorPrefix} Name can not be empty`;
  }
  const matchedEcomEvent = allowedEcomEvents.find((list) => list.name === name);
  if (!matchedEcomEvent) {
    throw `${errorPrefix} The name, "${name}", is not supported`;
  }
  if (!params || (params && !Object.keys(params).length)) {
    throw `${errorPrefix} Parameters can not be empty`;
  }
  const mismatchedEcomEventParam = Object.keys(params).find(
    (param) => !matchedEcomEvent.parameters.includes(param)
  );
  if (mismatchedEcomEventParam) {
    throw `${errorPrefix} The parameter, "${mismatchedEcomEventParam}" is not supported`;
  }
  if (matchedEcomEvent.requiredParameters) {
    const missingRequiredEcomEventParams = matchedEcomEvent.requiredParameters.find(
      (requiredParameter) => !Object.keys(params).includes(requiredParameter)
    );
    if (missingRequiredEcomEventParams) {
      throw `${errorPrefix} The parameter, "${missingRequiredEcomEventParams}", is required for "${name}"`;
    }
  }

  const matchedEcomEventOnProps = requiredEcomEventProperties.find(
    (requiredProp) => Object.keys(params).includes(requiredProp.parameter)
  );
  if (matchedEcomEventOnProps) {
    let props = params[matchedEcomEventOnProps.parameter];
    if (Array.isArray(props)) {
      matchedEcomEventOnProps.properties.forEach((property) => {
        props.forEach((prop) => {
          if (!prop[property] || !Object.keys(prop).includes(property)) {
            throw `${errorPrefix} The property, "${property}", is required for "${matchedEcomEventOnProps.parameter}"`;
          }
        });
      });
    } else {
      matchedEcomEventOnProps.properties.forEach((property) => {
        if (!props[property] || !Object.keys(props).includes(property)) {
          throw `${errorPrefix} The property, "${property}", is required for "${matchedEcomEventOnProps.parameter}"`;
        }
      });
    }
  }

  return;
};

export const verifyCustom = (custom) => {
  const dimensions = Object.values(allowedCustomDimensions);
  const dimensionsKeys = Object.keys(allowedCustomDimensions);
  const metrics = Object.values(allowedCustomMetrics);
  const metricsKeys = Object.keys(allowedCustomMetrics);
  const customs = Object.keys(custom);
  customs.forEach((cust) => {
    if (
      !(
        dimensions.includes(cust) ||
        metrics.includes(cust) ||
        dimensionsKeys.includes(cust) ||
        metricsKeys.includes(cust)
      )
    ) {
      throw `${errorPrefix} ${cust} is not a supported custom dimension or metric`;
    }
  });
};

export const addToCustom = (manualPageView, skipContentGroups = false) => {
  let custom = {};
  if (window.location.search.includes('itm_')) {
    const queries = getQueries(window.location.search);
    if (queries.itm_content && queries.itm_element && queries.itm_campaign) {
      const { itm_content, itm_element, itm_campaign } = queries;
      custom = {
        itm_content,
        itm_element,
        itm_campaign,
      };
    }
  }
  // adform tracking
  if (window.location.search.includes('utm_term')) {
    let adform_cookie_id;
    const queries = getQueries(window.location.search);
    if (queries.utm_custom || (window.Adform && window.Adform._uid)) {
      adform_cookie_id = queries.utm_custom
        ? queries.utm_custom
        : window.Adform._uid;
    }
    const adform_line_item_id = queries.utm_term;
    custom = {
      ...custom,
      adform_cookie_id,
      adform_line_item_id,
    };
  }
  //Salesforce marketing cloud id tracking
  if (window.location.search.includes('sfmc_id')) {
    const queries = getQueries(window.location.search);
    if (queries.sfmc_id) {
      const { sfmc_id } = queries;
      custom = {
        sfmc_id,
      };
    }
  }

  // navigation tracking
  if (navigationData) {
    custom = {
      ...custom,
      ...navigationData,
    };
  }
  // content grouping test
  if (!skipContentGroups) {
    if (getPageType() === 'PIP') {
      custom = {
        ...custom,
        content_group1: 'Product_Page',
        content_group2: 'Exploration',
      };
    }
    if (getPageType() === 'PLP - home') {
      custom = {
        ...custom,
        content_group1: 'Homepage',
      };
    }
    if (getPageType() === 'PLP - campaigns') {
      custom = {
        ...custom,
        content_group1: 'Campaigns',
        content_group2: 'Inspiration',
      };
    }
    if (getPageType() === 'PLP - range') {
      custom = {
        ...custom,
        content_group1: 'Category',
        content_group2: 'Exploration',
      };
    }
    if (getPageType() === 'PLP - rooms') {
      custom = {
        ...custom,
        content_group1: 'Rooms',
        content_group2: 'Inspiration',
      };
    }
    if (getPageType() === 'PLP - new') {
      custom = {
        ...custom,
        content_group1: 'New',
        content_group2: 'Inspiration',
      };
    }
    if (getPageType() === 'Offers') {
      custom = {
        ...custom,
        content_group1: 'Offers',
        content_group2: 'Inspiration',
      };
    }
    if (getPageType() === 'Stores') {
      custom = {
        ...custom,
        content_group1: 'Stores',
        content_group2: 'Purchase',
      };
    }
    if (getPageType() === 'This - is - IKEA') {
      custom = {
        ...custom,
        content_group1: 'This is IKEA',
        content_group2: 'Retention',
      };
    }
    if (getPageType() === 'Sustainability') {
      custom = {
        ...custom,
        content_group1: 'Sustainability',
      };
    }
    if (getPageType() === 'Ideas') {
      custom = {
        ...custom,
        content_group1: 'Ideas',
        content_group2: 'Inspiration',
      };
    }
    if (getPageType() === 'Ikea_Family') {
      custom = {
        ...custom,
        content_group1: 'Ikea Family',
        content_group2: 'Retention',
      };
    }
    if (getPageType() === 'Ikea_Business') {
      custom = {
        ...custom,
        content_group1: 'Ikea Business',
        content_group2: 'Inspiration',
      };
    }
    if (getPageType() === 'Customer_Service') {
      custom = {
        ...custom,
        content_group1: 'Customer Service',
        content_group2: 'Retention',
      };
    }
    if (getPageType() === 'Product_Guides') {
      custom = {
        ...custom,
        content_group1: 'Product Guides',
      };
    }
    if (getPageType() === 'Planners') {
      custom = {
        ...custom,
        content_group1: 'Planners',
        content_group2: 'Exploration',
      };
    }
  }


  const productListVariant = getProductListVariant();
  if (productListVariant) {
    custom = {
      ...custom,
      product_list_variant: productListVariant,
    };
  }

  if (!manualPageView) {
    custom = {
      ...custom,
      team: 'analytics',
    }
  }

  return custom;
};
export const getQueryString = () => decodeURIComponent(window.location.search.slice(1).toLowerCase());

export const getQueries = (query) => {
  return query
    .substr(query.indexOf('?') + 1)
    .split('&')
    .reduce((prev, curr) => {
      const [k, v] = curr.split('=');
      return { ...prev, [k]: decodeURIComponent(v) };
    }, {});
};

export const getPageType = () => {
  if (window.location.pathname.includes('/search/')) return 'search';
  if (window.location.pathname.includes('/recommendations/'))
    return 'recommendations';
  if (window.location.pathname.includes('/rooms/')) return 'PLP - rooms';
  if (window.location.pathname.includes('/new/')) return 'PLP - new';
  if (window.location.pathname.includes('/browse-history/'))
    return 'PLP - browse history';
  if (window.location.pathname.includes('/campaigns/'))
    return 'PLP - campaigns';
  if (window.location.pathname.includes('/offers/')) return 'Offers';
  if (window.location.pathname.includes('/ideas/')) return 'Ideas';
  if (window.location.pathname.includes('/ikea-family/')) return 'Ikea_Family';
  if (window.location.pathname.includes('/ikea-business/'))
    return 'Ikea_Business';
  if (window.location.pathname.includes('/customer-service/'))
    return 'Customer_Service';
  if (window.location.pathname.includes('/product-guides/'))
    return 'Product_Guides';
  if (window.location.pathname.includes('/stores/')) return 'Stores';
  if (
    window.location.pathname.includes(
      '/planners/' || '/addon-app/' || '/vpui.htm/'
    )
  )
    return 'Planners';
  if (window.location.pathname.includes('/this-is-ikea/'))
    return 'This - is - IKEA';
  if (window.location.pathname.includes('/sustainable-everyday/'))
    return 'Sustainability';
  if (window.location.pathname.length < 8) return 'PLP - home';
  if (window.utag_data && window.utag_data.page_type === 'product listing')
    return 'PLP - range';
  if (
    window.utag_data &&
    window.utag_data.page_type === 'product information page'
  )
    return 'PIP';
};

export const getProductListVariant = () => {
  return window.utag_data && window.utag_data.page_category_level;
};

export const getLoggedIn = () => new Promise((resolve, reject) => {
  if (window.ikea && window.ikea.authentication) {
    window.ikea.authentication.getTokens((error, token) => {
      if(error) {
        resolve(false);
      } else {
        resolve(!!token.auth);
      }
    });
  } else {
    resolve(false);
  }
});

export const getLoggedInUserData = () => new Promise((resolve, reject) => {
  if (window.ikea && window.ikea.authentication) {
    window.ikea.authentication.getTokens((error, token) => {
      if(error) {
        resolve({ });
      } else {
        let party_uid = null;
        let customer_type = null;
        let token_value = null;

        if (token.auth && token.auth.value) {
          token_value = token.auth.value;
          const base64Url = token_value.split('.')[1];
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));

          party_uid = JSON.parse(jsonPayload)['https://accounts.ikea.com/partyUId'];
          customer_type = JSON.parse(jsonPayload)['https://accounts.ikea.com/customerType'];
        }
        resolve({ party_uid, customer_type, token_value });
      }
    });
  } else {
    resolve({ });
  }
});

export const getUserLocalTimezone = () => {
  const now = new Date();
  return now.getTimezoneOffset();
}

export const getUserLocalTime = () => {
  // Get local time as ISO string with offset at the end
  const now = new Date();
  const tzo = -now.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = function (num) {
    const norm = Math.abs(Math.floor(num));
    return (norm < 10 ? '0' : '') + norm;
  };
  return (
    now.getFullYear() +
    '-' +
    pad(now.getMonth() + 1) +
    '-' +
    pad(now.getDate()) +
    'T' +
    pad(now.getHours()) +
    ':' +
    pad(now.getMinutes()) +
    ':' +
    pad(now.getSeconds()) +
    '.' +
    pad(now.getMilliseconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  );
};


export const sendError = (error) => {
  if (window.ikea && window.ikea.sentry) {
    window.ikea.sentry.store(
      error || new Error('Unknown error'), // The error object you caught
      '5739660', // The Sentry project ID
      '38938168dccc4b358d0846bc6ce4cbcd', // The Sentry project key
      'cmp-analytics', // The product name
      '1.0.0-beta', // The product release version
      0.05 // 5% sample rate
    );
  } else {
    console.error(error);
  }
};

export const registerErrorHandling = () => {
  if (window.ikea && window.ikea.sentry) {
    window.ikea.sentry.register(
      '5739660', // The Sentry project ID
      '38938168dccc4b358d0846bc6ce4cbcd', // The Sentry project key
      'cmp-analytics', // The product name
      '1.0.0-beta', // The product release version
      0.05, // 5% sample rate
      [/\/[a-z0-9]{2}\/[a-z0-9]{2}\/analytics\/scripts\//] // Paths to register for errors in
    );
  }
};

const createContentSquareMatchingKey = (cn) => {
  const cmk = Math.random() + "_" + Date.now();

  const tld = (function () {
    let i = 0,
      domain = document.domain,
      p = domain.split("."),
      s = "_cs_tld" + new Date().getTime();
    while (i < p.length - 1 && document.cookie.indexOf(s + "=" + s) === -1) {
      domain = p.slice(-1 - ++i).join(".");
      document.cookie = s + "=" + s + ";domain=" + domain + ";SameSite=None;Secure";
    }
    document.cookie = s + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + domain + ";";
    return domain;
  })();

  const now = new Date();
  let time = now.getTime();
  time += 30 * 60 * 1000;
  now.setTime(time);

  document.cookie = cn + "=" + cmk + "; expires=" + now.toUTCString() + ";path=/;domain=" + tld + ";SameSite=None;Secure";

  return cmk;
};


export const getContentSquareMatchingKey = () => {
  const cn = "_cs_mk_ga";
  const cookies = "; " + document.cookie;
  if (cookies) {
    const getCookie = cookies.split("; " + cn + "=");

    if (getCookie.length === 1) {
      return createContentSquareMatchingKey(cn);
    } else {
      return getCookie[1].split(";")[0];
    }
  }
};

export const getGA4TrackingId = () => {
  const trackingID = ga4TrackingIds[country];

  if (!trackingID) {
    return null;
  }

  return PROD ? trackingID : ga4TrackingIds['global_test']
};

export const getGTMTrackingId = () => {
  return gtmTrackingIds[country];
};

export const getCookieValue = (cname) => {
  return document.cookie
    .split('; ')
    .find(row => row.startsWith(cname))
    ?.split('=')[1];
}

export const isOptimizelyValid = (eventObj) => {
  const { event_category, event_action, event_label } = eventObj;
  if (event_category === 'optimizely' && event_action === 'user bucketed') {
    if (event_label && !event_label.match(/^\(\d+\):\(\d+\)$/)) {
      return false;
    }

    const NOW = new Date().getTime();
    const HALF_HOUR = 30 * 60 * 1000;
    const optimizelyLs = JSON.parse(localStorage.getItem("episod_optimizely"));

    if (optimizelyLs) {
      const { events } = optimizelyLs;

      const label = events.find(event => event.label === event_label);

      if (label) {
        if (NOW - label.timestamp < HALF_HOUR) {
          return false;
        } else {
          label.timestamp = NOW;
          localStorage.setItem("episod_optimizely", JSON.stringify({ events }));
          return true;
        }
      } else {
        events.push({ label: event_label, timestamp: NOW });
        localStorage.setItem("episod_optimizely", JSON.stringify({ events }));
        return true;
      }
    } else {
      localStorage.setItem("episod_optimizely", JSON.stringify({ events: [{ label: event_label, timestamp: NOW }] }));
      return true;
    }
  } else {
    return true;
  }
}
