import { gaTrackingIds, country, gtmTrackingIds } from './constants';

export function gtag() {
  window.gaDataLayer.push(arguments);
}

export const injectGtag = (countryCode = '') => {
  const cc = countryCode || country;
  const trackingId = gtmTrackingIds[cc];

  if (!trackingId){
    return Promise.resolve()
  }

  return new Promise((resolve, reject) => {
    try {
      var ad_storage_flag = 'denied';
      var analytics_storage_flag = 'denied';
      var allow_google_signals_flag = false;
      var allow_ad_personalization_signals_flag = false;
      var ad_user_data_flag = 'denied';
      var ad_personalization_flag = 'denied';

      if (ikea.cookieConsent) {

        if (ikea.cookieConsent.hasConsent(2)) {
          analytics_storage_flag = 'granted';
        }

        if (ikea.cookieConsent.hasConsent(4)) {
          ad_storage_flag = 'granted',
          ad_user_data_flag ='granted',
          ad_personalization_flag= 'granted',
          allow_google_signals_flag= true,
          allow_ad_personalization_signals_flag= true
        }

        gtag('consent', 'default', {
          'ad_storage': ad_storage_flag,
          'ad_user_data': ad_user_data_flag,
          'ad_personalization': ad_personalization_flag,
          'analytics_storage': analytics_storage_flag,
          'allow_google_signals': allow_google_signals_flag,
          'allow_ad_personalization_signals': allow_ad_personalization_signals_flag
        });
   //     console.log('consent set loader');
      }
      else {
        gtag('consent', 'default', {
          'ad_storage': 'granted',
          'ad_user_data': 'granted',
          'ad_personalization': 'granted',
          'analytics_storage': 'granted',
          'allow_google_signals': true,
          'allow_ad_personalization_signals': true
        });

      }
    }
    catch (error) {
      console.error('An error occurred within the consent code in ga-loader.js :', error);
    }

    let script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}&l=gaDataLayer`;
    script.addEventListener('load', resolve);
    script.addEventListener('error', () => reject('Error loading gtag.'));
    script.addEventListener('abort', () => reject('Gtag loading aborted.'));
    document.head.appendChild(script);
  });
};

export const createInjectedGtagEvent = () => {
  const event = document.createEvent('Event');
  event.initEvent('gtagLoaded', true, true);
  document.dispatchEvent(event);
};
