import {
  eventLogger,
  getQueryString,
  verifyEvent,
  verifyEcomEvent,
  getSessionID as getPageviewId,
  getContentSquareMatchingKey,
  getLoggedInUserData,
} from '../src/helpers';
import {
  allowedEvents,
  allowedEcomEvents,
  allowedCustomDimensions,
  allowedCustomMetrics,
} from '../src/whitelist';
import { dispatch as dispatchInhouse } from '../src/inhouse';
import { dispatch as dispatchGA4, config as configGA4 } from '../src/ga4';
import { init } from '@ingka-group-digital/episod-client-js';
/*import {
  country
} from '../src/constants';

const cookieConsentCountries = process.env.cookieConsentCountries;*/

window.gaDataLayer = window.gaDataLayer || [];

let site_language, site_market, site_platform, planner, DEBUG;

const gaTrackingIds = {
};

const gtmTrackingIds = {
  ae: 'G-S4EX53B760',
  at: 'G-S4EX53B760',
  au: 'G-S4EX53B760',
  be: 'G-S4EX53B760',
  bh: 'G-S4EX53B760',
  ca: 'G-S4EX53B760',
  ch: 'G-S4EX53B760',
  cl: 'G-S4EX53B760',
  co: 'G-S4EX53B760',
  cz: 'G-S4EX53B760',
  de: 'G-S4EX53B760',
  dk: 'G-S4EX53B760',
  eg: 'G-S4EX53B760',
  es: 'G-S4EX53B760',
  fi: 'G-S4EX53B760',
  fr: 'G-S4EX53B760',
  gb: 'G-S4EX53B760',
  hr: 'G-S4EX53B760',
  hu: 'G-S4EX53B760',
  ie: 'G-S4EX53B760',
  il: 'G-S4EX53B760',
  in: 'G-S4EX53B760',
  it: 'G-S4EX53B760',
  jo: 'G-S4EX53B760',
  jp: 'G-S4EX53B760',
  kr: 'G-S4EX53B760',
  kw: 'G-S4EX53B760',
  ma: 'G-S4EX53B760',
  mx: 'G-S4EX53B760',
  my: 'G-S4EX53B760',
  nl: 'G-S4EX53B760',
  no: 'G-S4EX53B760',
  nz: 'G-S4EX53B760',
  om: 'G-S4EX53B760',
  ph: 'G-S4EX53B760',
  pl: 'G-S4EX53B760',
  pt: 'G-S4EX53B760',
  qa: 'G-S4EX53B760',
  ro: 'G-S4EX53B760',
  rs: 'G-S4EX53B760',
  sa: 'G-S4EX53B760',
  se: 'G-S4EX53B760',
  sg: 'G-S4EX53B760',
  si: 'G-S4EX53B760',
  sk: 'G-S4EX53B760',
  th: 'G-S4EX53B760',
  ua: 'G-S4EX53B760',
  us: 'G-S4EX53B760',
};

const ignoreCountries = ['ru'];

function gtag() {
  window.gaDataLayer.push(arguments);
}

const injectGtag = (cc) => {
  const gaTrackingId  = gaTrackingIds[cc];
  const gtmTrackingId = gtmTrackingIds[cc];
  const trackingId = gaTrackingId || gtmTrackingId;

  if (!trackingId){
    return Promise.resolve()
  }

  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}&l=gaDataLayer`;
    script.addEventListener('load', resolve);
    script.addEventListener('error', () => reject('Error loading gtag.'));
    script.addEventListener('abort', () => reject('Gtag loading aborted.'));
    document.head.appendChild(script);
  });
};

const initEpisod = async (config) => {
  const { PROD, language, country } = config;
  const cookieDomain = PROD ? '.ikea.com' : '.ikeadt.com';
  const url = PROD ? 'https://web-api.ikea.com/episod/inhouseEvent' : 'https://web-api.cte.ikeadt.com/episod/inhouseEvent';
  const xClientId = PROD ? '1ea83eeb-f16f-43d0-a11d-2487f62fb456' : 'a0bb26de-64e2-4000-8b31-076cdb84c29d';

  const { party_uid, customer_type } = await getLoggedInUserData();

  const commons= {
    pageview_id: getPageviewId,
    market_code: country,
    event_details: {
      location_language: language,
    },
    client_details: {
      cs_key: getContentSquareMatchingKey(),
      party_uid,
      customer_type,
    },
  }

  init({
    cookieDomain,
    url,
    xClientId,
    commons,
  })
}

window.analyticsInit = async (initObj) => {
  const {
    countryCode,
    languageCode,
    platform,
    plannerName = '',
    dev = false,
  } = initObj;

  const missingReqParam =
    [{ countryCode }, { languageCode }, { platform }].find(
      (value) => !Object.values(value)[0]
    ) || {};
  if (Object.keys(missingReqParam).length) {
    return Promise.reject(
      `Required input parameter "${Object.keys(missingReqParam)[0]}" is missing`
    );
  }
  site_language = languageCode;
  site_market = countryCode;
  site_platform = platform;
  planner = plannerName;
  DEBUG = dev;
  await initEpisod({ PROD: !dev, language: languageCode, country: countryCode });
  return await injectGtag(site_market);
};

window.sendEvent = (eventObj = {}) => {
  if (ignoreCountries.includes(site_market)) {
    return;
  }

  const {
    event_category,
    name,
    page_path = '',
    page_location = '',
    custom = {},
  } = eventObj;
  if (!event_category && !name) {
    console.error('GA error: event_category and name are missing');
    return;
  }
  if (event_category === 'pageview') {
    sendPageview(page_path, page_location, custom);
    return;
  }
  if (DEBUG) {
    eventLogger(eventObj);
  }

  try {
    dispatchInhouse({ ...JSON.parse(JSON.stringify(eventObj)), site_platform, planner_name: planner },
      {
        initialPageView: false,
        PROD: !DEBUG,
        country: site_market,
        language: site_language,
      });
  } catch (ex) {
    console.error('Inhouse event not sent');
  }

  try {
    dispatchGA4(JSON.parse(JSON.stringify(eventObj)));
  }
  catch (ex) {
    console.error('GA4 event not sent. '+ex);
  }

  dispatchCustomEvent(eventObj);
};

const dispatchCustomEvent = (eventObj) => {
  const { event_category, name } = eventObj;
  const eventName = event_category ? event_category : name;
  const event = new CustomEvent(eventName, { detail: { ...eventObj } });
  window.dispatchEvent(event);
};
const sendPageview = (page_path, page_location, custom = {}) => {
  if (ignoreCountries.includes(site_market)) {
    return;
  }
  const blankHtml = window.location.href.includes('blank.html');

  if (DEBUG) {
    console.log('pageview:', {
      site_market,
      site_language,
      site_platform,
      page_path,
      page_location,
      query_parameter: getQueryString(),
      user_agent: navigator.userAgent,
      page_type: planner ? `planner_${planner.toLowerCase()}` : '',
      planner,
      ...custom,
    });
  }
  try {
    gtag('js', new Date());

    const commonConfig = {
      ...(blankHtml && { send_page_view: false }),
      custom_map: { ...allowedCustomDimensions, ...allowedCustomMetrics },
      site_market,
      site_language,
      site_platform,
      page_path,
      page_location,
      page_title: document.title,
      query_parameter: getQueryString(),
      user_agent: navigator.userAgent,
      page_type: planner ? `planner_${planner.toLowerCase()}` : '',
      planner,
      ...custom,
      anonymize_ip: true,
      cookie_expires: 13 * 30 * 24 * 60 * 60, // 13 months, in seconds
      cookie_update: false,
      allow_ad_personalization_signals: false,
    };

    try {
      configGA4(commonConfig);
    } catch (ex) {
      console.error('GA4 Configuration Error'+ex);
    }

  } catch (ex) {
    console.error('Configuration and pageview not sent');
  }
  if(!blankHtml){
    try {
      dispatchInhouse({ event_category: 'pageview', page_path, page_location, custom, site_platform, planner_name: planner },
        {
          initialPageView: true,
          PROD: !DEBUG,
          country: site_market,
          language: site_language,
        });
    } catch (ex) {
      console.error('Inhouse event not sent');
    }
  }
};

window.getAllCustom = () => {
  return { ...allowedCustomDimensions, ...allowedCustomMetrics };
};

window.getAllEvents = () => {
  return [...allowedEvents, ...allowedEcomEvents];
};
